import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";
import Market from '../views/market/market.vue'
import Sell from '../views/market/sell.vue'
import Record from '../views/market/record.vue'
import Mine from '../views/nft_mine/mine.vue'
import kapian from '../views/nft_mine/kapian.vue'
import NFT from '../views/nft_box/nft.vue'
import NFTchild from '../views/nft_box/nftchild.vue'
import jihuo from '../views/nft_mine/jihuo.vue'

Vue.use(VueRouter);

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [{
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: 'head.route1',
      type: 1,
    }
  },
  {
    path: "/market",
    name: "Market",
    component: Market,
    meta: {
      title: 'head.route1',
      type: 1,
    }
  },
  {
    path: "/sell",
    name: "Sell",
    component: Sell,
    meta: {
      title: 'head.route1',
      type: 3,
    }
  },
  {
    path: "/record",
    name: "Record",
    component: Record,
    meta: {
      title: 'head.route1',
      type: 3,
    }
  },
  {
    path: "/mine",
    name: "Mine",
    component: Mine,
    meta: {
      title: 'head.zz1',
      type: 2,
      content: 'head.zz2'
    }
  },
  {
    path: "/card",
    name: "Card",
    component: kapian,
    meta: {
      title: 'head.zz3',
      type: 2,
      content: 'head.zz4'
    }
  },
  {
    path: "/nft",
    name: "NFT",
    component: NFT,
    meta: {
      title: 'head.zz5',
      content: 'head.zz6',
      type: 2,
    },
  },
  {
    path: "/nftbox",
    name: "NFTbox",
    component: NFTchild,
    meta: {
      title: 'head.zz5',
      content: 'head.zz6',
      type: 2,
    },
  },
  {
    path: "/activate",
    name: "Activate",
    component: jihuo,
    meta: {
      title: 'head.zz7',
      content: 'head.zz8',
      type: 2,
    },
  },
];

const router = new VueRouter({
  routes,
});

export default router;