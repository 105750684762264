module.exports = {
    head: {
        zz1: "NFT挖矿",
        zz2: "查看您所拥有的NFT卡牌，获取挖矿收益!",
        zz3: "选择卡牌",
        zz4: "查看您所拥有的NFT卡牌，获取挖矿收益!",
        zz5: "NFT盲盒",
        zz6: "立即获取一只经典洋葱仔并赚取OMT",
        zz7: "购买激活卡",
        zz8: "激活洋葱的集卡卡槽",

        aa1: '规则',
        aa2: "规则介绍",
        aa3: 'OnionMixer—NFT是由一群推崇匿名交易洋葱发起，他们有的是工程师，有的是CEO，甚至还有的是加密行业的韭菜，这些洋葱的使命是为了让交易更加隐私，但是在这个努力的过程中，我们需要更多志同道合的粉丝一起推动洋葱进步，我们希望大家可以在nft的世界里，找到自己定位与角色，为洋葱发展贡献自己的意志。',
        aa4: "为了更好的优化onionmixer的挖矿玩法，我们将原来omtlp挖矿需改为nft挖矿，持有洋葱不同类别的nft就可以享受更多的omt分红。销售的nft和卡槽全部用于回购omt添加流动性或销毁。",
        aa5: "OMT价格",
        aa6: "NFT累积销售",
        aa7: "NFT累积回购",
        aa8: "卡槽累积收入",
        aa9: "累计回购销毁",

        bb1: "价格",
        bb2: "数量",
        bb3: "幸运抽奖",
        bb4: "开启盲盒随机获取假日系列经典卡，分别有25%概念获得:(卡片名待定)",
        bb5: "额外奖励",
        bb6: "集齐四张经典卡，额外获得20%产出奖励。",
        bb7: "放入卡槽",
        bb8: "将洋葱放置在对应卡槽中,开始赚取OMT吧!",
        bb9: "合计",
        bb10: "钱包余额",
        bb11: "立即购买",
        bb12: "去激活",
        bb13: "再次购买",

        cc1: "外卖小哥洋葱",
        cc2: "厨师洋葱",
        cc3: "房产销售洋葱",
        cc4: "程序员洋葱",
        cc5: "客户演示洋葱",
        cc6: "数据分析洋葱",
        cc7: "产品发布洋葱",
        cc8: "休闲学习洋葱",
        cc9: "高尔夫洋葱",
        cc10: "度假洋葱",
        cc11: "超跑洋葱",
        cc12: "飞行洋葱",

        cc13: "上班系列",
        cc14: "创业系列",
        cc15: "财务自由系列",

        dd1: "NFT盲盒",
        dd2: "立即获取一只经.....",
        dd3: "NFT挖矿",
        dd4: "立即获取一只经.....",
        dd5: "币币交易",
        dd6: "立即获取一只经.....",
        dd7: "卡片市场",
        dd8: "立即获取一只经.....",

        ee1:"收取",
        ee2:"已赚取",
        ee3:"钱包余额",
        ee4:"钱包里的卡",
        ee5:"卡槽卡",
        ee6:"去盲盒抽取",
        ee7:"激活挖矿的卡",
        ee8:"购买激活卡",
        ee9:"卡槽",
        ee10:"激活新的卡槽",
        ee11:"激活卡槽",
        ee12:"您的钱中没有激活卡",
        ee13:"激活卡",

        ff1:"功能介绍",
        ff2:"该卡可激活一个创业系列的一个卡槽，激活卡永久有效，且可与他人交换。",
        ff3:"超值优惠",
        ff4:"购买10%享9折优惠。",
        ff5:"立即购买",

        gg1:"去挂单",
        gg2:"挂单记录",
        gg3:"购买",
        gg4:"没有更多卡片了",
        gg5:"默认",
        gg6:"价格升序",
        gg7:"价格降序",

        
        hh1:"删除",
        hh2:"取消",
        hh3:"出售中",
        hh4:"已完成",

        ii1:"请选择需要出售的卡",
        ii2:"复选框",
        ii3:"没有更多卡片了",
        ii4:"出售",

        jj1:"加载中"

    }
}