<template>
  <div class="sellPage">
    <van-checkbox-group
      v-model="result"
      @change="ssssssss"
      :max="1"
      class="container"
    >
      <h4>{{$t('head.ii1')}}</h4>
      <div class="itemBox">
        <div
          class="item"
          v-for="(item, index) in this.allcords"
          :key="index"
          v-show="item != 0"
        >
          <img
            class="pic"
            v-lazy="imglist[item.type][item.tokenId - 1]"
            alt=""
          />
          <div class="h4" v-if="$i18n.locale == 'zh'">
            {{ alldata[item.type].cnname }}
          </div>
          <div class="h4" v-if="$i18n.locale == 'en'">
            {{ alldata[item.type].name }}
          </div>
          <div class="h2">{{ $t(list1[item.type][item.tokenId - 1]) }}</div>
          <div class="bottom">
            <div class="child1">
              <input
                class="price"
                v-model="amount[index]"
                type="text"
                placeholder="请输入"
              /><span class="price1">BNB</span>
            </div>
            <!-- <div class="btn"></div> -->
            <van-checkbox :name="index" class="btn">{{$t('head.ii2')}} a</van-checkbox>
          </div>
        </div>
        <div class="tip">{{$t('head.ii3')}}</div>
      </div>
      <div class="bottomDiv">
        <div class="sellBtn" @click="guadan()">{{$t('head.ii4')}}</div>
      </div>
    </van-checkbox-group>
    <div v-if="isLoading">
      <Loading></Loading>
    </div>
  </div>
</template>

<script>
import mixins from "../../mixins/index";
import { Multicall } from "../../model/multicall";
import img1 from "../../assets/market/img1.png";
import img2 from "../../assets/market/img2.png";
import img3 from "../../assets/market/img3.png";
import img4 from "../../assets/market/img4.png";
import img5 from "../../assets/market/img5.png";
import img6 from "../../assets/market/img6.png";
import img7 from "../../assets/market/img7.png";
import img8 from "../../assets/market/img8.png";
import img9 from "../../assets/market/img9.png";
import img10 from "../../assets/market/img10.png";
import img11 from "../../assets/market/img11.png";
import img12 from "../../assets/market/img12.png";
import Loading from "../../components/Loading.vue";
export default {
  data() {
    return {
      result: [],
      allcords: [],
      isLoading: false,
      amount: [],
      imglist: [
        [img1, img2, img3, img4],
        [img5, img6, img7, img8],
        [img9, img10, img11, img12],
      ],
      list1: [
        ["head.cc1", "head.cc2", "head.cc3", "head.cc4"],
        ["head.cc5", "head.cc6", "head.cc7", "head.cc8"],
        ["head.cc9", "head.cc10", "head.cc11", "head.cc12"],
      ],
      contract1: [
        { contract1: undefined },
        { contract2: undefined },
        { contract3: undefined },
      ],
    };
  },
  mixins: [mixins],
  watch: {
    "global.account"() {
      this.contract1 = this.contract1.map((item, index) => {
        item = new this.global.web3.eth.Contract(
          this.onionabi.ERC1155_abi,
          this.onion.erc1155[index]
        );
        return item;
      });
      this.contract.Market_contract = new this.global.web3.eth.Contract(
        this.onionabi.Market_abi,
        this.onion.Market
      );
      this.init();
    },
  },
  components: {
    Loading,
  },
  methods: {
    ssssssss() {
      console.log(this.result);
    },
    init() {
      this.isLoading = true;
      const multicall = new Multicall(this.onion.multicall, this.global.web3);
      const tokens = this.contract1.map((item, index) => {
        const data = {
          calls: item.methods.balanceOfBatch(
            [
              this.global.account,
              this.global.account,
              this.global.account,
              this.global.account,
            ],
            [1, 2, 3, 4]
          ),
          address: this.onion.erc1155[index],
        };
        return data;
      });

      multicall
        .aggregateCall(tokens)
        .then((res) => {
          console.log(res);
          const listdata = [];
          res.map((r, index) => {
            return r[0].map((s, tokenId) => {
              const it = Array(Number(s)).fill({
                type: index,
                tokenId: tokenId + 1,
              });
              it.map((res) => {
                listdata.push(res);
              });
              return it;
            });
          });
          console.log(listdata);
          this.allcords = listdata;
          //   this.allcords = this.allcords.concat(list[0]);
          //   this.allcords = this.allcords.concat(list[1]);
          //   this.allcords = this.allcords.concat(list[2]);

          // console.log(this.allcords);
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },

    // 挂单
    guadan() {
      console.log(this.result);
      if (this.isLoading) return;
      this.isLoading = true;
      this.contract.ERC1155_contract = new this.global.web3.eth.Contract(
        this.onionabi.ERC1155_abi,
        this.onion.erc1155[this.allcords[this.result].type]
      );
      const price = this.global.web3.utils.toHex(
        this.amount[this.result] * 1e18
      );
      this.contract.ERC1155_contract.methods
        .safeTransferFrom(
          this.global.account,
          this.onion.Market,
          this.allcords[this.result].tokenId,
          1,
          this.global.web3.eth.abi.encodeParameter("uint256", price)
        )
        .send({
          from: this.global.account,
        })
        .then((res) => {
          console.log(res);
          this.$notify({ type: "success", message: "SUCCESS" });
          this.isLoading = false;
          this.$router.go(0);
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.$notify({ type: "warning", message: err.message });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 9vh;
  padding-bottom: 130px;
}
.bottomDiv {
  width: 100%;
  height: 130px;
  background: #221c21;
  position: fixed;
  bottom: 0;
  left: 0;
  border-radius: 40px 40px 0px 0px;
  .sellBtn {
    width: 330px;
    height: 50px;
    background: #821973;
    opacity: 1;
    border-radius: 8px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: bold;
    line-height: 50px;
    color: #ffffff;
  }
}
h4 {
  font-size: 14px;
  font-weight: 800;
  color: #ffffff;
  padding: 25px 0 20px;
}
.itemBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 24px;
  .tip {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    opacity: 0.2;
    text-align: center;
    padding-bottom: 50px;
  }
  .item {
    width: 130px;
    height: 230px;
    background: #474246;
    border-radius: 8px;
    margin-bottom: 22px;
    padding: 14px;
    .pic {
      width: 100%;
    }
    .h4 {
      text-align: left;
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
      opacity: 0.6;
      padding-top: 12px;
    }
    .h2 {
      font-size: 13px;
      font-weight: 800;
      color: #ffffff;
      display: flex;
      align-items: baseline;
      padding-top: 6px;
      span {
        font-size: 10px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 0px;
        color: #ffffff;
        opacity: 0.2;
        transform: translateY(3px);
        padding-left: 5px;
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 18px;
      .child1 {
        display: flex;
        align-items: center;

        .price {
          width: 50px;
          height: 20px;
          background: rgba($color: #000000, $alpha: 0);
          border: 1px solid rgba($color: #fff, $alpha: 0.3);
          text-indent: 5px;
          font-size: 8px;
          font-weight: 800;
          color: #b932b6;
          display: flex;
          align-items: baseline;
          border-radius: 10px;
        }
        .price1 {
          font-size: 10px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 0px;
          color: #ffffff;
          opacity: 0.2;
          padding-left: 5px;
        }
      }
      .btn {
        width: 18px;
        height: 18px;
        border: 2px solid #ffffff;
        border-radius: 50%;
      }
    }
  }
}
</style>