<template>
  <div class="recordPage">
    <van-popup
      v-model="show"
      round
      position="bottom"
      closeable
      :style="{ height: '236px' }"
      class="mypop"
    >
      <div class="mpopbox1" @click="cancel()">{{ $t("head.hh1") }}</div>
      <div class="mpopbox2" @click="show = !show">{{ $t("head.hh2") }}</div>
    </van-popup>
    <div class="container">
      <div class="topVue">
        <div class="tab">
          <div
            class="tab_li"
            :class="{ active: active == 1 }"
            @click="changeTab(1)"
          >
            {{ $t("head.hh3") }}
          </div>
          <div
            class="tab_li"
            :class="{ active: active == 2 }"
            @click="changeTab(2)"
          >
            {{ $t("head.hh4") }}
          </div>
        </div>
        <!-- <div class="sel_section">程序员卡</div> -->
      </div>
      <div class="itemBox">
        <div class="item" v-for="(item, index) in allindata" :key="index">
          <img class="left" v-lazy="item.id[4]" alt="" />
          <!-- <div class="left" ></div> -->
          <div class="right">
            <div class="h4">{{ item.id[2] }}</div>
            <div class="h2">{{ $t(item.id[3]) }}</div>
            <div class="bottom">
              <div class="price">{{ item.price / 1e18 }} <span>BNB</span></div>
              <div
                class="btn"
                @click="showtime(item.id[0], item.id[1])"
                v-if="active == 1"
              >
                <div class="dot" v-for="index in 3" :key="index"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLoading">
      <Loading></Loading>
    </div>
  </div>
</template>

<script>
import mixins from "../../mixins/index";
import img1 from "../../assets/market/img1.png";
import img2 from "../../assets/market/img2.png";
import img3 from "../../assets/market/img3.png";
import img4 from "../../assets/market/img4.png";
import img5 from "../../assets/market/img5.png";
import img6 from "../../assets/market/img6.png";
import img7 from "../../assets/market/img7.png";
import img8 from "../../assets/market/img8.png";
import img9 from "../../assets/market/img9.png";
import img10 from "../../assets/market/img10.png";
import img11 from "../../assets/market/img11.png";
import img12 from "../../assets/market/img12.png";
import Loading from "../../components/Loading.vue";
export default {
  data() {
    return {
      show: false,
      active: 1,
      isLoading: false,
      imglist: [
        [img1, img2, img3, img4],
        [img5, img6, img7, img8],
        [img9, img10, img11, img12],
      ],
      list1: [
        ["head.cc1", "head.cc2", "head.cc3", "head.cc4"],
        ["head.cc5", "head.cc6", "head.cc7", "head.cc8"],
        ["head.cc9", "head.cc10", "head.cc11", "head.cc12"],
      ],
      allindata: [],
      address: "",
      pid: "",
    };
  },
  components: {
    Loading,
  },
  mixins: [mixins],
  watch: {
    "global.account"() {
      this.contract.Market_contract = new this.global.web3.eth.Contract(
        this.onionabi.Market_abi,
        this.onion.Market
      );
      this.init();
    },
  },
  methods: {
    init() {
      const data = {
        query: `{\n  auctions(where:{status:pending,seller:\"${this.global.account.toLowerCase()}\"}) {\n    id\n    price\n    tokenId\n    tokenId\n    seller\n    buyer\n    orderTime\n    status\n  }\n}`,
        variables: null,
      };
      this.getinit(data);
    },
    getinit(data) {
      this.isLoading = true;
      this.$axios
        .post(this.url, data)
        .then((res) => {
          console.log(res.data.data.auctions);
          this.allindata = res.data.data.auctions.map((item, index) => {
            item.id = item.id.split("-");
            this.onion.erc1155[index] = this.onion.erc1155[index].toLowerCase();
            const num = this.onion.erc1155.indexOf(item.id[0]);
            if (this.$i18n.locale == "zh") {
              this.$set(item.id, 2, this.alldata[num].cnname);
            }
            if (this.$i18n.locale == "en") {
              this.$set(item.id, 2, this.alldata[num].name);
            }
            this.$set(item.id, 3, this.list1[num][item.tokenId - 1]);
            this.$set(item.id, 4, this.imglist[num][item.tokenId - 1]);
            return item;
          });
          console.log(this.allindata);
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },

    // 删除
    cancel() {
      this.isLoading = true;
      this.contract.Market_contract.methods
        .cancelAuction(this.address, this.pid)
        .send({
          from: this.global.account,
        })
        .then((res) => {
          console.log(res);
          this.show = !this.show;
          this.isLoading = false;
          this.$notify({ type: "success", message: "SUCCESS" });
          this.init();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({ type: "warning", message: err.message });
          this.isLoading = false;
        });
    },
    showtime(address, pid) {
      console.log(address, pid);
      this.address = address;
      this.pid = pid;
      this.show = !this.show;
    },
    changeTab(index) {
      this.allindata = [];
      this.active = index;
      if (index == 1) {
        this.init();
      }
      if (index == 2) {
        const data = {
          query: `{\n  auctions(where:{buyer:\"${this.global.account.toLowerCase()}\"}) {\n    id\n    price\n    tokenId\n    tokenId\n    seller\n    buyer\n    orderTime\n    status\n  }\n}`,
          variables: null,
        };
        this.getinit(data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mypop {
  overflow: hidden;
  .mpopbox1 {
    cursor: pointer;
    width: 330px;
    height: 50px;
    border: 1px solid #821973;
    border-radius: 8px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 800;
    line-height: 0px;
    color: #821973;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 70px auto 20px;
  }
  .mpopbox2 {
    width: 330px;
    height: 52px;
    background: #821973;
    border-radius: 8px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 0px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
}
.container {
  margin-top: 9vh;
  padding-top: 0 20px;
  .itemBox {
    padding-top: 24px;
    .item {
      height: 98px;
      background: #474246;
      border-radius: 8px;
      display: flex;
      padding: 11px;
      margin-bottom: 20px;
      .left {
        width: 98px;
        height: 98px;
      }
      .right {
        padding-left: 24px;
        flex: 1;
        height: 98px;
        .h4 {
          text-align: left;
          font-size: 18px;
          font-weight: 600;
          color: #ffffff;
          padding-top: 4px;
        }
        .h2 {
          font-size: 14px;
          font-weight: 800;
          color: #ffffff;
          opacity: 0.6;
          display: flex;
          align-items: baseline;
          padding-top: 6px;
          span {
            font-size: 10px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 0px;
            color: #ffffff;
            opacity: 0.2;
            transform: translateY(3px);
            padding-left: 5px;
          }
        }
        .bottom {
          display: flex;
          justify-content: space-between;
          padding-top: 18px;
          .price {
            font-size: 16px;
            font-weight: 800;
            color: #b932b6;
            display: flex;
            align-items: baseline;

            span {
              font-size: 10px;
              font-family: PingFang SC;
              font-weight: 500;
              line-height: 0px;
              color: #ffffff;
              opacity: 0.2;
              padding-left: 5px;
            }
          }
          .btn {
            width: 28px;
            height: 18px;
            border: 1px solid #ffffff;
            border-radius: 14px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 0 2px;
            .dot {
              width: 4px;
              height: 4px;
              background: linear-gradient(90deg, #be35b3 0%, #6b1a9d 100%);
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}
.topVue {
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .tab {
    width: 130px;
    height: 36px;
    background: #221c21;
    border-radius: 50px;
    display: flex;
    .tab_li {
      width: 58px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      font-size: 12px;
      color: #7a7679;
      // margin: 4px 0 0 3.5px;
    }
    .active {
      background: linear-gradient(149deg, #be35b3 0%, #8715ce 100%);
      border-radius: 50px;
      color: #fff;
      font-weight: 600;
    }
  }
  .sel_section {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 20px;
    background: #221c21;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    border-radius: 9px;
    line-height: 32px;
  }
}
</style>
