import erc20 from './erc20.json';
import erc1155 from './erc1155.json';
import farmabi from './farmabi.json';
import shopabi from './shopabi.json';
import marketbi from './marketabi.json';
import multicallabi from './multicall.json';
import jaioyiduiabi from './jaioyidui.json';
export default {
	data() {
		return {
			global: {
				web3: undefined,
				account: undefined,
				BNBbalanceOf: 0,
				balanceOf: 0,
			},
			// url: "https://api.studio.thegraph.com/query/748/omt-bsc/v0.0.1",
			// onion: {
			//     erc1155: [ //erc1155 质押代币 购买盲盒用
			//         "0xB5B7CA4DfE3237F8F06E1C66CBF535c14A56c273", //系列1
			//         "0x7Efb96f7669B35B99B1A7F2dd49F8f5BC98062Ad", //系列2
			//         "0x6B466b5E2c139678BaA5B32099d87F6d986aad99", //系列3
			//     ],
			//     Omt: "0x6071826ba85d2Bb94E655000270C6e685cbb6914", //挖出的代币
			//     dtc: "0xfe0b09f3d250e461992509f8e70e73a1bd288f86",
			//     USDT: "0x2a4040550894Bb74f20dEf7F2Be8bFE5C385FB73",
			//     jiaoyidui: "0xb5fFe6f27979CBC996D4b5c02b98c8d185627048",
			//     Farm: "0x58C45604d054c6729F4Ab73435788Ac08417adc8", //农场
			//     Shop: "0x928d476dCE0AB58E230f5Fe555A2F478a4b79649", //盲盒
			//     Market: "0x7B21c6b67ea45ea7E73eDEA080597a34416bE232", //市场
			//     omtfee:"0x8fDEFC3d9cf712c65B518b881C9BAFb8176F97DD", //omt回购
			//     slotfee:"0xaf1954ec8f343ff6986F4e9338280f23ADD3D481",//卡槽回购
			//     multicall: '0xDC9Cf2346fA9709B71a50cc992b3F36f9096878b'

			// },
			// url: "https://api.studio.thegraph.com/query/748/omt/v0.0.9",
			// onion: {
			//     erc1155: [ //erc1155 质押代币 购买盲盒用
			//         "0x6409E1aA697Bdc93aF5921f22D03fcE4168a4380", //系列1
			//         "0x3C3aD1b0Ed9a158efbbE816CcCfD724af6fc1352", //系列2
			//         "0x31a66216681849D59f61A36885151ce9B01101b6", //系列3
			//     ],
			//     Omt: "0x112C354D5A6d1bD4FE4c10642C9aaf35918EA348", //挖出的代币
			//     dtc: "0xfe0b09f3d250e461992509f8e70e73a1bd288f86",
			//     USDT: "0x690C1352bE4Aeeab90B4852B90514583f1885678",
			//     jiaoyidui: "0xb5fFe6f27979CBC996D4b5c02b98c8d185627048",
			//     Farm: "0xA36c36c96e4f2F9396282095BFC21c154cb655b9", //农场
			//     Shop: "0x305b4501F7fD2eF7Ed05B8760d0A14E973eEC514", //盲盒
			//     Market: "0x43afa23F6B4e7a873a607C237acf07C5239C114f", //市场
			//     omtfee: "0x8fDEFC3d9cf712c65B518b881C9BAFb8176F97DD", //omt回购
			//     slotfee: "0xaf1954ec8f343ff6986F4e9338280f23ADD3D481",//卡槽回购
			//     multicall: '0xB65E920c0EBb6087f85904bA676a2e62Da04B013'

			// },
			url: 'https://api.studio.thegraph.com/query/748/omt/v0.0.9',
			onion: {
				erc1155: [
					//erc1155 质押代币 购买盲盒用
					'0xb260337CB68024207F5dDEf5D2ACe977A4E5E1cC', //系列1
					'0x3E33BC813c2238A475409005D069ED4752505648', //系列2
					'0xbF8ce99A400dD6A5A7afA6Ed08D2906dd0869833', //系列3
				],
				Omt: '0x27d8fE7C15986799559EeEf4418200736AA6D61e', //挖出的代币
				dtc: '0xfe0b09f3d250e461992509f8e70e73a1bd288f86',
				USDT: '0x9FBC22c0d5fA56365c8b4723a106e11c0C1861c8',
				jiaoyidui: '0xb5fFe6f27979CBC996D4b5c02b98c8d185627048',
				Farm: '0x59A0C9f7Ee66B8ce4CA90D00161a620B4Ef4Bc69', //农场
				Shop: '0xe15353Fe7cFE3336AC6E16fe0F45a21E2546A846', //盲盒
				Market: '0x726A452932A67DeA458BC4F6407b1DbC8c4c9069', //市场
				omtfee: '0x8fDEFC3d9cf712c65B518b881C9BAFb8176F97DD', //omt回购
				slotfee: '0xaf1954ec8f343ff6986F4e9338280f23ADD3D481', //卡槽回购
				multicall: '0xaef9511e1840a3CaC46673140E563B31a68F0d16',
			},
			onionabi: {
				ERC1155_abi: erc1155,
				Omt_abi: erc20,
				Farm_abi: farmabi,
				Market_abi: marketbi,
				Shop_abi: shopabi,
				multicall_abi: multicallabi,
				jiaoyidui_abi: jaioyiduiabi,
			},
			contract: {
				ERC1155_contract: undefined,
				Omt_contract: undefined,
				Farm_contract: undefined,
				Shop_contract: undefined,
				Market_contract: undefined,
				USDT_contract: undefined,
				multicall: undefined,
				jiaoyidui_contract: undefined,
			},
			alldata: [
				{
					name: 'Worker',
					cnname: '上班系列',
					price: '2000',
					slotPrice: '40',
					num: 1,
					tokens: [
						{
							tokenId: 1,
							duration: 3600 * 24 * 40,
							outRate: '0.00081018518518519',
						},
						{
							tokenId: 2,
							duration: 3600 * 24 * 30,
							outRate: '0.0012384259259259',
						},
						{
							tokenId: 3,
							duration: 3600 * 24 * 20,
							outRate: '0.0018518518518519',
						},
						{
							tokenId: 4,
							duration: 3600 * 24 * 10,
							outRate: '0.0034722222222222',
						},
					],
				},
				{
					name: 'Entrepreneur',
					cnname: '创业系列',
					price: '20000',
					slotPrice: '40',
					num: 1,
					tokens: [
						{
							tokenId: 1,
							duration: 3600 * 24 * 50,
							outRate: '0.0069444444444444',
						},
						{
							tokenId: 2,
							duration: 3600 * 24 * 40,
							outRate: '0.0092592592592593',
						},
						{
							tokenId: 3,
							duration: 3600 * 24 * 30,
							outRate: '0.011574074074074',
						},
						{
							tokenId: 4,
							duration: 3600 * 24 * 20,
							outRate: '0.016203703703704',
						},
					],
				},
				{
					name: 'Rich',
					cnname: '财务自由',
					price: '200000',
					slotPrice: '40',
					num: 1,
					tokens: [
						{
							tokenId: 1,
							duration: 3600 * 24 * 60,
							outRate: '0.11574074074074',
						},
						{
							tokenId: 2,
							duration: 3600 * 24 * 50,
							outRate: '0.13888888888889',
						},
						{
							tokenId: 3,
							duration: 3600 * 24 * 40,
							outRate: '0.16203703703704',
						},
						{
							tokenId: 4,
							duration: 3600 * 24 * 30,
							outRate: '0.20833333333333',
						},
					],
				},
			],
		};
	},
	created() {
		this.start();
		this.connectMetaMask();
		let self = this;
		setTimeout(function chechAuth() {
			if (window.ethereum.selectedAddress === null) setTimeout(chechAuth, 200);
			else self.global.account = window.ethereum.selectedAddress;
		}, 200);
	},
	methods: {
		start() {
			this.global.web3 = window.web3 = new this.Web3(window.ethereum);
			window.ethereum.autoRefreshOnNetworkChange = false;
			window.ethereum.on('accountsChanged', (item) => {
				console.log('accountsChanged', item[0]);
			});
			window.ethereum.on('networkChanged', () => {
				console.log('networkChanged');
			});
		},
		async connectMetaMask() {
			// if (window.ethereum.selectedAddress) {
			//     this.global.account = window.ethereum.selectedAddress
			// } else {
			window.ethereum
				.request({
					method: 'eth_requestAccounts',
				})
				.then((res) => {
					// console.log(res);
					this.global.account = res[0];
				})
				.catch((err) => {
					if (err.code === 4001) {
						// EIP-1193 userRejectedRequest error
						// If this happens, the user rejected the connection request.
						console.log('Please connect to MetaMask.');
					} else {
						console.error(err);
					}
				});
			// }
			// try {
			//     const newAccounts = await window.ethereum.request({
			//         method: "eth_requestAccounts",
			//     });
			//     this.global.account = newAccounts[0]
			//     // handleNeewAccounts(newAccounts);
			// } catch (error) {
			//     // this.$message.warning("权限请求已待处理");
			// }
		},
		Balanceof() {
			new this.global.web3.eth.getBalance(this.global.account).then((res) => {
				this.global.BNBbalanceOf = res;
			});
			this.contract.Omt_contract = new this.global.web3.eth.Contract(
				this.onionabi.Omt_abi,
				this.onion.Omt
			);
			console.log(this.contract.Omt_contract);
			this.contract.Omt_contract.methods
				.balanceOf(this.global.account)
				.call()
				.then((res) => {
					this.global.balanceOf = res;
				});
		},
	},
};
