module.exports = {
    head: {
        zz1:"NFT Dig",
        zz2:"Check out your NFT cards for mining benefits!",
        zz3:"Select The Card",
        zz4:"Check out your NFT cards for mining benefits!",
        zz5:"NFT Blind Box",
        zz6:"Get a classic onion and earn OMT immediately",
        zz7:"Purchase an Activation Card",
        zz8:"Activate the onion's chuck slot",

        aa1: 'Rule',
        aa2: "Rules is introduced",
        aa3: "OnionMixer -- NFT was started by a group of anonymous trading Onions, engineers, ceos, and even crypto industry leeks. The Onion's mission is to make transactions more private, but in the process, we need more like-minded fans to push the Onion forward.We hope that you can find your own position and role in the world of NFT and contribute your will to the development of Onion.",
        aa4: "In order to better optimize the mining gameplay of OnionMixer, we changed the original OMTLP mining to NFT mining, and holders of NFT of different categories of Onions could enjoy more OMT dividends.All NFT and card slots sold are used to repurchase OMT to add liquidity or destroy.",
        aa5: "OMT price",
        aa6: "NFT sales",
        aa7: "NFT repurchase",
        aa8: "Accumulated",
        aa9: "Cumulative",

        bb1: "Price",
        bb2: "Amount",
        bb3: "Lucky draw",
        bb4: "Open the blind box and randomly acquire holiday classic cards, 25% of each concept will be awarded :(card name to be determined)",
        bb5: "Premiums",
        bb6: "Collect four classic cards and earn an additional 20% output bonus.",
        bb7: "Into the card slot",
        bb8: "Place the onion in the appropriate slot and start earning OMT!",
        bb9: "Total",
        bb10: "Balance Of",
        bb11: "Purchase",
        bb12: "Activate",
        bb13: "Repurchase",

        cc1: "Take boy",
        cc2: "Cook",
        cc3: "Estate agent",
        cc4: "Programmer",
        cc5: "Customer demo",
        cc6: "Data analysis",
        cc7: "Product release",
        cc8: "Casual learning",
        cc9: "Golf",
        cc10: "Holiday",
        cc11: "Supercar",
        cc12: "Flight",

        cc13: "Worker",
        cc14: "Entrepreneur",
        cc15: "Rich",


        
        dd1:"NFT Blind Box",
        dd2:"Get a sutra immediately.....",
        dd3:"NFT Dig",
        dd4:"Get a sutra immediately.....",
        dd5:"Swap",
        dd6:"Get a sutra immediately.....",
        dd7:"Market",
        dd8:"Get a sutra immediately.....",
        
        ee1:"Claim",
        ee2:"Have to earn",
        ee3:"Balance",
        ee4:"Wallet card",
        ee5:"Slot card",
        ee6:"Go to blind box extraction",
        ee7:"Activate the mining card",
        ee8:"Purchase an Activation Card",
        ee9:"Card slot",
        ee10:"Activate a new card slot",
        ee11:"Activate the card slot",
        ee12:"There is no activation card in your money",
        ee13:"Activate the card",

        
        ff1:"Function Introduction ",
        ff2:"The card activates one slot in a startup series, and the activation card is permanent and exchangeable with others.",
        ff3:"Steep discount",
        ff4:"Buy 10% and get 10% discount.",
        ff5:"Purchase",

        
        gg1:"Go deity",
        gg2:"Order",
        gg3:"Buy",
        gg4:"There are no more cards",
        gg5:"Default",
        gg6:"Ascending",
        gg7:"Descending",

        hh1:"Remove",
        hh2:"Cancel",
        hh3:"On offer",
        hh4:"Finished",


        
        ii1:"Please select the card you want to sell",
        ii2:"Check box",
        ii3:"There are no more cards",
        ii4:"Sell",

        
        jj1:"Loading"
    }
}