<template>
  <div id="app">
    <Header @childbyvalue="childbyvalue" />
    <router-view v-show="show" class="router" />
  </div>
</template>
<script>
import Header from "./components/Header.vue";
export default {
  data() {
    return {
      show: true,
    };
  },
  methods: {
    childbyvalue(value) {
      console.log(value);
      this.show = value;
    },
  },
  components: {
    Header,
  },
};
</script>

<style lang="scss">
html {
  background: #000000;
}
* {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  min-height: 100vh;
  background: url("./assets/bg.png") no-repeat;
  background-size: 100% 100%;
  overflow: auto;
  .router {
    // height: calc(100vh);
    // overflow: auto;
    // top: 34px;
    padding: 0px 18px;
    // margin-bottom: 20px;
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.van-dropdown-menu__bar {
  background: #221c21 !important;
  height: 14px !important;
}

.van-dropdown-menu__title::after {
  background: #221c21 !important;
}

.van-dropdown-menu__title {
  color: #ffffff !important;
  font-size: 12px !important;
}
</style>
