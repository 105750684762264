<template>
</template>

<script>
import mixins from "../../mixins/index";
export default {
  data() {
    return {
    };
  },
  mixins: [mixins],
  watch: {
    "global.account"() {
      this.contract.Shop_contract.events
        .BlindBox({ filter: {}, fromBlock: 0 })
        .on("data", function (event) {
          console.log(event, 1);
        })
        .on("changed", function (event) {
          console.log(event, 2);
        })
        .on("error", function (err) {
          console.log(err);
        });
      this.Balanceof();
    },
  },
  created() {
    this.title = this.$route.query.title;
    this.num = this.$route.query.num;
    this.price = this.$route.query.price;
    this.token = this.$route.query.token;
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
</style>