<template>
  <div class="jihuoPage">
    <div class="notbox0">
      <img class="child1" src="../../assets/nft_mine/bg2.png" alt="" />
    </div>
    <div class="nftbox1" ref="nftbox1">
      <div class="box1">
        <div
          class="child1"
          v-for="(item, index) in alldata"
          :key="item.cnname"
          @click="foucs(index)"
          v-show="$i18n.locale == 'zh'"
        >
          {{ item.cnname }}
        </div>
        <div
          class="child1"
          v-for="(item, index) in alldata"
          :key="item.name"
          @click="foucs(index)"
          v-show="$i18n.locale == 'en'"
        >
          {{ item.name }}
        </div>
      </div>
      <div
        class="box2"
        v-for="(item, index) in alldata"
        :key="index"
        v-show="index == flag"
      >
        <div class="child1">
          <div class="son1">{{ $t("head.bb1") }}</div>
          <div class="son2">
            {{ item.slotPrice }}<span class="sonn">USDT</span>
          </div>
        </div>
        <div class="child2">
          <div class="son1">{{ $t("head.bb2") }}</div>
          <div class="son2">
            <div class="sonn1" @click="reduce(index)">-</div>
            <div class="sonn2">{{ item.num }}</div>
            <div class="sonn3" @click="add(index)">+</div>
          </div>
        </div>
      </div>
    </div>
    <div class="nftbox2">
      <div class="child1">{{ $t("head.ff1") }}</div>
      <div class="child2">
        {{ $t("head.ff2") }}
      </div>
      <div class="child1">{{ $t("head.ff3") }}</div>
      <div class="child2">{{ $t("head.ff4") }}</div>
    </div>
    <div class="nftbox3" @click="buyslot()">{{ $t("head.ff5") }}</div>
    <div v-if="isLoading">
      <Loading></Loading>
    </div>
  </div>
</template>

<script>
import mixins from "../../mixins/index";
import bg1 from "../../assets/ntf_box/ntfbox1.png";
import bg2 from "../../assets/ntf_box/ntfbox2.png";
import bg3 from "../../assets/ntf_box/ntfbox3.png";
import Loading from "../../components/Loading.vue";
export default {
  data() {
    return {
      flag: "0",
      num: 1,
      price: "",
      isLoading: false,
    };
  },
  components: {
    Loading,
  },
  mixins: [mixins],
  watch: {
    "global.account"() {
      this.contract.Shop_contract = new this.global.web3.eth.Contract(
        this.onionabi.Shop_abi,
        this.onion.Shop
      );
      this.contract.USDT_contract = new this.global.web3.eth.Contract(
        this.onionabi.Omt_abi,
        this.onion.USDT
      );
    },
  },
  methods: {
    foucs(index) {
      this.flag = index;
      console.log(this.title);
      // this.title1 = this.title[index].title;
      // this.price = this.title[index].price;
      console.log(index);
      switch (index) {
        case 0:
          this.$refs.nftbox1.style.background = `url(${bg1})`;
          this.$refs.nftbox1.style.backgroundSize = `100% 100%`;
          break;
        case 1:
          this.$refs.nftbox1.style.background = `url(${bg2})`;
          this.$refs.nftbox1.style.backgroundSize = `100% 100%`;
          break;
        case 2:
          this.$refs.nftbox1.style.background = `url(${bg3})`;
          this.$refs.nftbox1.style.backgroundSize = `100% 100%`;
          break;
      }
    },
    add(index) {
      this.alldata[index].num++;
      this.num = this.alldata[index].num;
    },
    reduce(index) {
      if (this.alldata[index].num == 1) {
        this.alldata[index].num = 1;
      } else {
        this.alldata[index].num--;
      }
      this.num = this.alldata[index].num;
    },
    buyslot() {
      const num1 = this.alldata[this.flag].num;
      const num2 = this.global.web3.utils.toBN(
        this.alldata[this.flag].slotPrice
      );
      this.isLoading = true;
      // console.log(this.onion.erc1155[this.flag] ,this.num, "0x",new this.$Decimal(this.alldata[this.flag].num)
      //         .mul(new this.$Decimal(this.alldata[this.flag].slotPrice))
      //         .mul(new this.$Decimal(1e18))
      //         .toNumber());
      this.contract.USDT_contract.methods
        .approve(
          this.onion.Shop,
          this.global.web3.utils.toWei(
            this.global.web3.utils.toBN(num1 * num2),
            "ether"
          )
        )
        .send({
          from: this.global.account,
        })
        .then((res) => {
          this.contract.Shop_contract.methods
            .slot(this.onion.erc1155[this.flag], this.num, "0x")
            .send({
              from: this.global.account,
              // value: this.global.web3.utils.toBN(
              //   new this.$Decimal(this.alldata[this.flag].num)
              //     .mul(new this.$Decimal(this.alldata[this.flag].slotPrice))
              //     .mul(new this.$Decimal(1e18))
              //     .toNumber()
              // ),
            })
            .then((res) => {
              this.isLoading = false;
              console.log(res);
              this.$notify({ type: "success", message: "SUCCESS" });
            })
            .catch((err) => {
              this.isLoading = false;
              console.log(err);
              this.$notify({ type: "warning", message: err.message });
            });
        })
        .catch((err) => {
          this.isLoading = false;
          this.$notify({ type: "warning", message: err.message });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.jihuoPage {
  .notbox0 {
    width: 100%;
    height: 300px;
    background: url("../../assets/nft_mine/bg1.png") no-repeat;
    background-size: 120% 120%;
    background-position-x: -30px;
    display: flex;
    justify-content: center;
    align-items: center;
    .child1 {
      width: 160px;
    }
  }
  .nftbox1 {
    height: 179px;
    background: url("../../assets/ntf_box/ntfbox1.png");
    background-size: 100% 100%;
    margin-bottom: 15px;
    .box1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      .child1 {
        flex: 1;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 500;
      }
    }
    .box2 {
      box-sizing: border-box;
      padding: 20px 30px;
      .child1 {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .son1 {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          opacity: 0.7;
          display: flex;
          align-items: center;
        }
        .son2 {
          font-size: 30px;
          font-family: STHupo;
          font-weight: 400;
          .sonn {
            font-size: 12px;
            font-family: STHupo;
            font-weight: 400;
            opacity: 0.33;
            margin-left: 3px;
          }
        }
      }
      .child2 {
        display: flex;
        justify-content: space-between;
        .son1 {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          opacity: 0.7;
          display: flex;
          align-items: center;
        }
        .son2 {
          width: 108px;
          height: 48px;
          background: url("../../assets/ntf_box/bg1.png");
          background-size: 100% 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          .sonn1 {
          }
          .sonn2 {
            font-size: 30px;
            font-family: STHupo;
            font-weight: 400;
          }
          .sonn3 {
          }
        }
      }
    }
  }
  .nftbox2 {
    width: 340px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    text-align: left;
    box-sizing: border-box;
    padding: 20px 30px;
    margin-bottom: 34px;
    .child1 {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 500;
      margin-bottom: 5px;
    }
    .child2 {
      font-size: 10px;
      font-family: PingFang SC;
      font-weight: 400;
      opacity: 0.4;
      margin-bottom: 15px;
    }
  }
  .nftbox3 {
    height: 45px;
    background: #821973;
    border-radius: 10px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>