<template>
  <div class="homeConnect">
    <div class="selectBox">
      <div class="left">
        <div class="sel_section">
          <span @click="toggle1()">{{ $t(name1) }}</span>
          <img src="../../assets/up.png" v-if="isShow" />
          <img src="../../assets/down.png" v-else />
          <div class="sec_content" v-if="isShow">
            <div class="th" v-for="(item, index) in list0" :key="index">
              {{ $t(item.name) }}
              <div
                class="td"
                @click="select1(oitem.title, item.id, oitem.pid)"
                v-for="(oitem, oindex) in item.dataList"
                :key="oindex"
              >
                {{ $t(oitem.title) }}
              </div>
            </div>
          </div>
        </div>
        <div class="sel_section">
          <span @click="toggle2()">{{ $t(name2) }}</span>
          <img src="../../assets/up.png" v-if="isDefault" />
          <img src="../../assets/down.png" v-else />
          <div class="sec_content" v-if="isDefault">
            <div
              class="td"
              @click="select2(oitem.title, oitem.id)"
              v-for="(oitem, oindex) in list2"
              :key="oindex"
            >
              {{ $t(oitem.title) }}
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <span class="guadan" @click="navigate('/sell')">{{
          $t("head.gg1")
        }}</span>
        <span class="list" @click="navigate('/record')">{{
          $t("head.gg2")
        }}</span>
      </div>
    </div>
    <div class="itemBox">
      <div class="item" v-for="(item, index) in allindata" :key="index">
        <img class="pic" v-lazy="item.id[4]" alt="" />
        <div class="h4">{{ item.id[2] }}</div>
        <div class="h2">
          {{ $t(item.id[3]) }}<span>#{{ item.tokenId }}</span>
        </div>
        <div class="bottom">
          <div class="price">{{ item.price / 1e18 }} <span>BNB</span></div>
          <div class="btn" @click="buycord(item.id[0], item.id[1], item.price)">
            {{ $t("head.gg3") }}
          </div>
        </div>
      </div>
      <div class="tip">{{ $t("head.gg4") }}</div>
    </div>
    <div v-if="isLoading">
      <Loading></Loading>
    </div>
  </div>
</template>

<script>
import mixins from "../../mixins/index";
import img1 from "../../assets/market/img1.png";
import img2 from "../../assets/market/img2.png";
import img3 from "../../assets/market/img3.png";
import img4 from "../../assets/market/img4.png";
import img5 from "../../assets/market/img5.png";
import img6 from "../../assets/market/img6.png";
import img7 from "../../assets/market/img7.png";
import img8 from "../../assets/market/img8.png";
import img9 from "../../assets/market/img9.png";
import img10 from "../../assets/market/img10.png";
import img11 from "../../assets/market/img11.png";
import img12 from "../../assets/market/img12.png";
import Loading from "../../components/Loading.vue";
export default {
  data() {
    return {
      isShow: false,
      isDefault: false,
      name1: "head.cc1",
      name2: "head.gg5",
      imglist: [
        [img1, img2, img3, img4],
        [img5, img6, img7, img8],
        [img9, img10, img11, img12],
      ],
      list0: [
        {
          name: "head.cc13",
          id: 1,
          dataList: [
            { title: "head.cc1", pid: 1 },
            { title: "head.cc2", pid: 2 },
            { title: "head.cc3", pid: 3 },
            { title: "head.cc4", pid: 4 },
          ],
        },
        {
          name: "head.cc14",
          id: 2,
          dataList: [
            { title: "head.cc5", pid: 1 },
            { title: "head.cc6", pid: 2 },
            { title: "head.cc7", pid: 3 },
            { title: "head.cc8", pid: 4 },
          ],
        },
        {
          name: "head.cc15",
          id: 3,
          dataList: [
            { title: "head.cc9", pid: 1 },
            { title: "head.cc10", pid: 2 },
            { title: "head.cc11", pid: 3 },
            { title: "head.cc12", pid: 4 },
          ],
        },
      ],
      list1: [
        ["head.cc1", "head.cc2", "head.cc3", "head.cc4"],
        ["head.cc5", "head.cc6", "head.cc7", "head.cc8"],
        ["head.cc9", "head.cc10", "head.cc11", "head.cc12"],
      ],
      list2: [
        { title: "head.gg5", id: 0 },
        { title: "head.gg6", id: 1 },
        { title: "head.gg7", id: 2 },
      ],
      allindata: [],
      isLoading: false,
    };
  },
  mixins: [mixins],
  watch: {
    "global.account"() {
      this.contract.ERC1155_contract = new this.global.web3.eth.Contract(
        this.onionabi.ERC1155_abi,
        this.onion.erc1155[0]
      );
      this.contract.Shop_contract = new this.global.web3.eth.Contract(
        this.onionabi.Shop_abi,
        this.onion.Shop
      );
      this.contract.Market_contract = new this.global.web3.eth.Contract(
        this.onionabi.Market_abi,
        this.onion.Market
      );
      this.init();
    },
  },
  components: {
    Loading,
  },
  methods: {
    init() {
      this.isLoading = true;
      const data = {
        query:
          "{\n  auctions(where:{status:pending}) {\n    id\n    price\n    tokenId\n    token\n    seller\n    buyer\n    orderTime\n    bidTime\n    status\n  }\n}\n",
        variables: null,
      };
      this.getinit(data);
    },
    getinit(data) {
      this.$axios
        .post(this.url, data)
        .then((res) => {
          console.log(res.data.data.auctions, "1111111");
          this.allindata = res.data.data.auctions.map((item, index) => {
            item.id = item.id.split("-");
            this.onion.erc1155[index] = this.onion.erc1155[index].toLowerCase();
            const num = this.onion.erc1155.indexOf(item.id[0]);
            console.log(item, "1.5");
            console.log(num, "sssssssssss");
            if (this.$i18n.locale == "zh") {
              this.$set(item.id, 2, this.alldata[num].cnname);
            }
            if (this.$i18n.locale == "en") {
              this.$set(item.id, 2, this.alldata[num].name);
            }
            this.$set(item.id, 3, this.list1[num][item.tokenId - 1]);
            this.$set(item.id, 4, this.imglist[num][item.tokenId - 1]);
            return item;
          });
          this.isLoading = false;
          console.log(this.allindata, "222222");
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },

    buycord(token, index, price) {
      if (this.isLoading) return;
      this.isLoading = true;
      console.log(this.contract.Market_contract);
      this.contract.Market_contract.methods
        .bid(token, index)
        .send({
          from: this.global.account,
          value: price,
        })
        .then((res) => {
          console.log(res);
          this.isLoading = false;
          this.$notify({ type: "success", message: "SUCCESS" });
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    navigate(path) {
      this.$router.push({
        path: path,
      });
    },

    select1(oitem, id, pid) {
      console.log(id, pid);
      this.name1 = oitem;
      this.isShow = false;
      const data = {
        query: `{\n  auctions(where:{status:pending,tokenId:\"${pid}\",token:\"${
          this.onion.erc1155[id - 1]
        }\"}) {\n    id\n    price\n    tokenId\n    token\n    seller\n    buyer\n    orderTime\n    status\n  }\n}\n`,
        variables: null,
      };
      this.getinit(data);
    },
    select2(oitem, pid) {
      console.log(oitem, pid);
      this.name2 = oitem;
      this.isDefault = false;
      const data = {
        query:
          "{\n  auctions(where:{status:pending}) {\n    id\n    price\n    tokenId\n    token\n    seller\n    buyer\n    orderTime\n    bidTime\n    status\n  }\n}\n",
        variables: null,
      };
      const data1 = {
        query:
          "{\n  auctions(orderBy: price,, orderDirection: asc,where:{status:pending}) {\n    id\n    price\n    tokenId\n    token\n    seller\n    buyer\n    orderTime\n    bidTime\n    status\n  }\n}\n",
        variables: null,
      };
      const data2 = {
        query:
          "{\n  auctions(orderBy: price,, orderDirection: desc,where:{status:pending}) {\n    id\n    price\n    tokenId\n    token\n    seller\n    buyer\n    orderTime\n    bidTime\n    status\n  }\n}\n",
        variables: null,
      };
      switch (pid) {
        case 0:
          this.getinit(data);
          break;
        case 1:
          this.getinit(data1);
          break;
        case 2:
          this.getinit(data2);
          break;
      }
    },
    toggle1() {
      if (this.isDefault) this.isDefault = false;
      this.isShow = !this.isShow;
    },
    toggle2() {
      if (this.isShow) this.isShow = false;
      this.isDefault = !this.isDefault;
    },
  },
};
</script>

<style lang="scss" scoped>
.selectBox {
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 9vh;
  .left {
    display: flex;
    align-items: center;
    .sel_section {
      display: flex;
      align-items: center;
      height: 28px;
      padding: 0 10px;
      background: #221c21;
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
      border-radius: 10px;
      line-height: 28px;
      margin-right: 18px;
      position: relative;
      img {
        width: 10px;
        height: 10px;
        margin-left: 8px;
      }
      .sec_content {
        position: absolute;
        width: 90px;
        background: rgba($color: #221c21, $alpha: 0.9);
        border-radius: 20px;
        left: 0;
        top: 34px;
        padding: 15px;
        text-align: left;
        z-index: 99;
        .th {
          font-size: 12px;
          font-weight: 500;
          color: rgba($color: #ffffff, $alpha: 0.4);
          .td {
            font-size: 12px;
            font-weight: 500;
            color: rgba($color: #ffffff, $alpha: 1);
            padding-left: 10px;
          }
        }
      }
    }
  }
  .right {
    .guadan {
      font-size: 12px;
      font-weight: 800;
      color: #b932b6;
    }
    .list {
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
      opacity: 0.4;
      padding-left: 20px;
    }
  }
}
.itemBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 24px;
  .tip {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    opacity: 0.2;
    text-align: center;
    padding-bottom: 50px;
  }
  .item {
    width: 130px;
    background: #474246;
    border-radius: 8px;
    margin-bottom: 22px;
    padding: 14px;
    overflow: hidden;
    .pic {
      width: 100%;
      // height: 130px;
    }
    .h4 {
      text-align: left;
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
      opacity: 0.6;
      padding-top: 12px;
    }
    .h2 {
      font-size: 16px;
      font-weight: 800;
      color: #ffffff;
      display: flex;
      align-items: baseline;
      padding-top: 6px;
      span {
        font-size: 10px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 0px;
        color: #ffffff;
        opacity: 0.2;
        transform: translateY(3px);
        padding-left: 5px;
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      padding-top: 18px;
      .price {
        font-size: 16px;
        font-weight: 800;
        color: #b932b6;
        display: flex;
        align-items: baseline;

        span {
          font-size: 10px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 0px;
          color: #ffffff;
          opacity: 0.2;
          padding-left: 5px;
        }
      }
      .btn {
        width: 43px;
        height: 24px;
        background: linear-gradient(
          42deg,
          #c338b0 0%,
          #be35b3 0%,
          #951dc7 59%,
          #8715ce 100%
        );
        opacity: 1;
        border-radius: 24px;
        line-height: 24px;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}
</style>