<template>
  <div class="header" id="header">
    <div class="box1">
      <img
        class="child1"
        src="../assets/header/logo.png"
        @click="$router.push('/')"
      />
      <div class="child2" v-if="!global.account" @click="connectMetaMask">
        Connect
      </div>
      <img
        class="child3"
        v-if="global.account"
        src="../assets/header/nav.png"
        @click="block"
      />
    </div>
    <van-popup
      v-model="show"
      position="top"
      :style="{ height: '92vh', marginTop: '8vh' }"
      :transition-appear="true"
      :overlay="false"
      class="box2"
    >
      <div class="childbox1">
        <div class="child1">
          <div class="son1">
            {{ account }}
          </div>
          <div class="son2" @click="end()">
            <img class="sonn" src="../assets/header/end.png" />
          </div>
        </div>
        <div
          class="child2"
          v-if="$i18n.locale == 'zh'"
          @click="$i18n.locale = 'en'"
        >
          EN
        </div>
        <div
          class="child2"
          v-if="$i18n.locale == 'en'"
          @click="$i18n.locale = 'zh'"
        >
          ZH
        </div>
      </div>
      <div class="childbox3">
        <div
          class="child1"
          v-for="item in navlist"
          :key="item.title"
          @click="navigate(item.path)"
        >
          <img class="ss1" :src="item.img" alt="" />
          <div class="ss2">{{ item.title }}</div>
          <div class="ss3">{{ item.jianjie }}</div>
        </div>
      </div>
    </van-popup>
    <!-- <div class="box2" ref="tanchuang">
      <div class="childbox1">
        <div class="child1">
          <div class="son1">
            {{ account }}
          </div>
          <div class="son2" @click="end()">
            <img class="sonn" src="../assets/header/end.png" />
          </div>
        </div>
        <div class="child2"></div>
      </div>
      <div class="childbox3">
        <div
          class="child1"
          v-for="item in navlist"
          :key="item.title"
          @click="navigate(item.path)"
        >
          <div>{{ item.title }}</div>
        </div>
      </div>
    </div> -->
    <div class="box3" v-if="$route.meta.type == 2">
      <div class="child0"></div>
      <div class="child1">{{ $t($route.meta.title) }}</div>
      <div class="child2">{{ $t($route.meta.content) }}</div>
    </div>
  </div>
</template>

<script>
import mixins from "../mixins/index";
import img1 from "../assets/header/nav1.png";
import img2 from "../assets/header/nav2.png";
import img3 from "../assets/header/nav3.png";
import img4 from "../assets/header/nav4.png";
export default {
  data() {
    return {
      flag: false,
      account: "",
      show: false,
    };
  },
  computed: {
    navlist() {
      return [
        {
          title: this.$t("head.dd1"),
          jianjie: this.$t("head.dd2"),
          img: img1,
          path: "/nft",
        },
        {
          title: this.$t("head.dd3"),
          jianjie: this.$t("head.dd4"),
          img: img2,
          path: "/mine",
        },
        {
          title: this.$t("head.dd5"),
          jianjie: this.$t("head.dd6"),
          img: img3,
          path: "/jiaoyi",
        },
        {
          title: this.$t("head.dd7"),
          jianjie: this.$t("head.dd8"),
          img: img4,
          path: "/market",
        },
      ];
    },
  },
  mixins: [mixins],
  watch: {
    "global.account"() {
      console.log(this.$i18n.locale);
      if (this.global.account) {
        this.account =
          this.global.account.slice(0, 4) +
          "..." +
          this.global.account.slice(
            this.global.account.length - 5,
            this.global.account.length
          );
      }
    },
  },
  methods: {
    block() {
      // console.log(this.$refs.tanchuang.style);
      // this.flag = !this.flag;
      this.show = !this.show;
      // this.$emit("childbyvalue", !this.flag);
      // if (this.flag == false) {
      //   this.$refs.tanchuang.style.height = "0px";
      //   this.$refs.tanchuang.style.transition = "height 1s";
      // } else {
      //   this.$refs.tanchuang.style.height = "500px";
      //   this.$refs.tanchuang.style.transition = "height 1s";
      // }
    },
    navigate(path) {
      if (path != "/jiaoyi") {
        this.$router.push({
          path: path,
        });
        this.show = !this.show;
      } else {
        window.location.href =
          "https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x943978707717D4d73dA6F74312AeF59feaFE368c";
      }
    },
    end() {
      this.global.account = undefined;
      this.account = undefined;
      // this.$refs.tanchuang.style.height = "0px";
      // this.$refs.tanchuang.style.transition = "height 0.5s";
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: sticky;
  top: 0;

  z-index: 2000;
  .box1 {
    position: absolute;
    top: 0;
    z-index: 3000;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 8vh;
    overflow: hidden;
    background: url("../assets/header/bg1.png") no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 0px 18px;
    // background: rgba(0, 0, 0, 1);
    // background: rgba(255, 255, 255, 0.2);
    // box-shadow: -19px 13px 64px 1px rgba(0, 0, 0, 0.07);
    .child1 {
      width: 87px;
      height: 33px;
    }
    .child2 {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 72px;
      height: 26px;
      background: #821973;
      border-radius: 26px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
    }
    .child3 {
      width: 20px;
      height: 15px;
      margin-top: 10px;
    }
  }
  .box2 {
    width: 100vw;
    height: 0px;
    overflow: hidden;
    background: rgba(0, 0, 0, 1);
    box-shadow: -19px 13px 64px 1px rgba(0, 0, 0, 0.07);
    // transition: height 0.5s;
    // -moz-transition: height 0.5s;
    // -webkit-transition: height 0.5s;
    // &:hover{
    //   height: 500px;
    // }
    .childbox1 {
      height: 75px;
      background: url("../assets/header/bg2.png") no-repeat;
      background-size: 100% 100%;
      box-shadow: -19px 13px 64px 1px rgba(0, 0, 0, 0.07);
      border-radius: 0px 0px 25px 25px;
      box-sizing: border-box;
      padding: 20px 18px 0;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      .child1 {
        width: 40%;
        display: flex;
        justify-content: space-between;
        .son1 {
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: PingFang SC;
          font-weight: bold;
          color: #821973;
          width: 89px;
          height: 26px;
          background: #ffffff;
          border-radius: 13px;
        }
        .son2 {
          width: 26px;
          height: 26px;
          background: #ffffff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          .sonn {
            width: 15px;
            height: 15px;
          }
        }
      }
      .child2 {
        width: 68px;
        height: 26px;
        background: rgba($color: #7f63e6, $alpha: 0.32);
        border-radius: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
      }
    }
    .childbox3 {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      box-sizing: border-box;
      padding: 30px 30px;
      text-align: left;
      color: #000000;
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: bold;
      text-indent: 10px;
      .child1 {
        margin-bottom: 30px;
        width: 138px;
        height: 138px;
        background: rgba($color: #ffffff, $alpha: 0.5);
        border-radius: 15px;
        box-sizing: border-box;
        padding: 15px 10px;
        color: #fff;
        .ss1 {
          width: 35px;
          height: 35px;
          margin-bottom: 30px;
        }
        .ss3 {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: rgba($color: #fff, $alpha: 0.59);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .box3 {
    height: 210px;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: -19px 13px 64px 1px rgba(0, 0, 0, 0.07);
    border-radius: 0px 0px 25px 25px;
    box-sizing: border-box;
    padding: 90px 40px 0;
    overflow: hidden;
    text-align: left;
    .child1 {
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 800;
    }
    .child2 {
      margin-top: 8px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      opacity: 0.3;
    }
  }
}
</style>