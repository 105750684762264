import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from 'vue-i18n'
import Web3 from 'web3'
import Vant from 'vant';
import { Lazyload } from 'vant';
import 'vant/lib/index.css';
import { Decimal } from 'decimal.js';
import axios from 'axios'

Vue.use(Lazyload);
Vue.prototype.$Decimal = Decimal
Vue.prototype.$axios = axios
Vue.use(Vant);
Vue.use(VueI18n)
Vue.prototype.Web3 = Web3
Vue.config.productionTip = false;


const i18n = new VueI18n({
  locale: 'en', // 语言标识 //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    'en': require('./i18n/en'), //英文
    'zh': require('./i18n/zh'), //中文
  }
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
