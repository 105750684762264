<template>
	<div class="kapian">
		<!-- <div class="box1">选择放入的卡牌</div> -->
		<van-checkbox-group class="box2" v-model="result" :max="1">
			<div
				class="child1"
				v-for="(item, index) in backgd[current]"
				:key="index"
				:style="
					time == 0
						? allcords[index + 1] == 0
							? item.style1
							: item.style
						: item.style1
				"
			>
				<van-checkbox
					:name="item.name"
					:ref="item.ref"
					:disabled="
						time == 0 ? (allcords[index + 1] == 0 ? true : false) : true
					"
					>{{ $t(item.title) }}</van-checkbox
				>
			</div>
		</van-checkbox-group>
		<div class="box3" @click="jihuo(result)">添加</div>
		<div v-if="isLoading">
			<Loading></Loading>
		</div>
	</div>
</template>

<script>
import mixins from '../../mixins/index';
import img1 from '../../assets/nft_mine/kapian1.png';
import img2 from '../../assets/nft_mine/kapian2.png';
import img3 from '../../assets/nft_mine/kapian3.png';
import img4 from '../../assets/nft_mine/kapian4.png';
import img5 from '../../assets/nft_mine/kapian5.png';
import img6 from '../../assets/nft_mine/kapian6.png';
import img7 from '../../assets/nft_mine/kapian7.png';
import img8 from '../../assets/nft_mine/kapian8.png';
import img9 from '../../assets/nft_mine/kapian9.png';
import img10 from '../../assets/nft_mine/kapian10.png';
import img11 from '../../assets/nft_mine/kapian11.png';
import img12 from '../../assets/nft_mine/kapian12.png';
import Loading from '../../components/Loading.vue';
export default {
	data() {
		return {
			result: [],
			isLoading: false,
			soltnum: '',
			address: '',
			current: '',
			allcords: [],
			time: [],
			backgd: [
				[
					{
						name: '1',
						ref: 'a',
						title: 'head.cc1',
						style: {
							background: `url(${img1}) no-repeat`,
							backgroundSize: '100% 100%',
						},
						style1: {
							background: `url(${img1}) no-repeat`,
							backgroundSize: '100% 100%',
							'-webkit-filter': 'grayscale(100%)',
							'-moz-filter': 'grayscale(100%)',
							'-ms-filter': 'grayscale(100%)',
							'-o-filter': 'grayscale(100%)',
							filter: 'grayscale(100%)',
							filter: 'gray',
						},
					},
					{
						name: '2',
						ref: 'b',
						title: 'head.cc2',
						style: {
							background: `url(${img2}) no-repeat`,
							backgroundSize: '100% 100%',
						},
						style1: {
							background: `url(${img2}) no-repeat`,
							backgroundSize: '100% 100%',
							'-webkit-filter': 'grayscale(100%)',
							'-moz-filter': 'grayscale(100%)',
							'-ms-filter': 'grayscale(100%)',
							'-o-filter': 'grayscale(100%)',
							filter: 'grayscale(100%)',
							filter: 'gray',
						},
					},
					{
						name: '3',
						ref: 'c',
						title: 'head.cc3',
						style: {
							background: `url(${img3}) no-repeat`,
							backgroundSize: '100% 100%',
						},
						style1: {
							background: `url(${img3}) no-repeat`,
							backgroundSize: '100% 100%',
							'-webkit-filter': 'grayscale(100%)',
							'-moz-filter': 'grayscale(100%)',
							'-ms-filter': 'grayscale(100%)',
							'-o-filter': 'grayscale(100%)',
							filter: 'grayscale(100%)',
							filter: 'gray',
						},
					},
					{
						name: '4',
						ref: 'd',
						title: 'head.cc4',
						style: {
							background: `url(${img4}) no-repeat`,
							backgroundSize: '100% 100%',
						},
						style1: {
							background: `url(${img4}) no-repeat`,
							backgroundSize: '100% 100%',
							'-webkit-filter': 'grayscale(100%)',
							'-moz-filter': 'grayscale(100%)',
							'-ms-filter': 'grayscale(100%)',
							'-o-filter': 'grayscale(100%)',
							filter: 'grayscale(100%)',
							filter: 'gray',
						},
					},
				],
				[
					{
						name: '1',
						ref: 'a',
						title: 'head.cc5',
						style: {
							background: `url(${img5}) no-repeat`,
							backgroundSize: '100% 100%',
						},
						style1: {
							background: `url(${img5}) no-repeat`,
							backgroundSize: '100% 100%',
							'-webkit-filter': 'grayscale(100%)',
							'-moz-filter': 'grayscale(100%)',
							'-ms-filter': 'grayscale(100%)',
							'-o-filter': 'grayscale(100%)',
							filter: 'grayscale(100%)',
							filter: 'gray',
						},
					},
					{
						name: '2',
						ref: 'b',
						title: 'head.cc6',
						style: {
							background: `url(${img6}) no-repeat`,
							backgroundSize: '100% 100%',
						},
						style1: {
							background: `url(${img6}) no-repeat`,
							backgroundSize: '100% 100%',
							'-webkit-filter': 'grayscale(100%)',
							'-moz-filter': 'grayscale(100%)',
							'-ms-filter': 'grayscale(100%)',
							'-o-filter': 'grayscale(100%)',
							filter: 'grayscale(100%)',
							filter: 'gray',
						},
					},
					{
						name: '3',
						ref: 'c',
						title: 'head.cc7',
						style: {
							background: `url(${img7}) no-repeat`,
							backgroundSize: '100% 100%',
						},
						style1: {
							background: `url(${img7}) no-repeat`,
							backgroundSize: '100% 100%',
							'-webkit-filter': 'grayscale(100%)',
							'-moz-filter': 'grayscale(100%)',
							'-ms-filter': 'grayscale(100%)',
							'-o-filter': 'grayscale(100%)',
							filter: 'grayscale(100%)',
							filter: 'gray',
						},
					},
					{
						name: '4',
						ref: 'd',
						title: 'head.cc8',
						style: {
							background: `url(${img8}) no-repeat`,
							backgroundSize: '100% 100%',
						},
						style1: {
							background: `url(${img8}) no-repeat`,
							backgroundSize: '100% 100%',
							'-webkit-filter': 'grayscale(100%)',
							'-moz-filter': 'grayscale(100%)',
							'-ms-filter': 'grayscale(100%)',
							'-o-filter': 'grayscale(100%)',
							filter: 'grayscale(100%)',
							filter: 'gray',
						},
					},
				],
				[
					{
						name: '1',
						ref: 'a',
						title: 'head.cc9',
						style: {
							background: `url(${img9}) no-repeat`,
							backgroundSize: '100% 100%',
						},
						style1: {
							background: `url(${img9}) no-repeat`,
							backgroundSize: '100% 100%',
							'-webkit-filter': 'grayscale(100%)',
							'-moz-filter': 'grayscale(100%)',
							'-ms-filter': 'grayscale(100%)',
							'-o-filter': 'grayscale(100%)',
							filter: 'grayscale(100%)',
							filter: 'gray',
						},
					},
					{
						name: '2',
						ref: 'b',
						title: 'head.cc10',
						style: {
							background: `url(${img10}) no-repeat`,
							backgroundSize: '100% 100%',
						},
						style1: {
							background: `url(${img10}) no-repeat`,
							backgroundSize: '100% 100%',
							'-webkit-filter': 'grayscale(100%)',
							'-moz-filter': 'grayscale(100%)',
							'-ms-filter': 'grayscale(100%)',
							'-o-filter': 'grayscale(100%)',
							filter: 'grayscale(100%)',
							filter: 'gray',
						},
					},
					{
						name: '3',
						ref: 'c',
						title: 'head.cc11',
						style: {
							background: `url(${img11}) no-repeat`,
							backgroundSize: '100% 100%',
						},
						style1: {
							background: `url(${img11}) no-repeat`,
							backgroundSize: '100% 100%',
							'-webkit-filter': 'grayscale(100%)',
							'-moz-filter': 'grayscale(100%)',
							'-ms-filter': 'grayscale(100%)',
							'-o-filter': 'grayscale(100%)',
							filter: 'grayscale(100%)',
							filter: 'gray',
						},
					},
					{
						name: '4',
						ref: 'd',
						title: 'head.cc12',
						style: {
							background: `url(${img12}) no-repeat`,
							backgroundSize: '100% 100%',
						},
						style1: {
							background: `url(${img12}) no-repeat`,
							backgroundSize: '100% 100%',
							'-webkit-filter': 'grayscale(100%)',
							'-moz-filter': 'grayscale(100%)',
							'-ms-filter': 'grayscale(100%)',
							'-o-filter': 'grayscale(100%)',
							filter: 'grayscale(100%)',
							filter: 'gray',
						},
					},
				],
			],
		};
	},
	components: {
		Loading,
	},
	mixins: [mixins],
	watch: {
		'global.account'() {
			this.contract.ERC1155_contract = new this.global.web3.eth.Contract(
				this.onionabi.ERC1155_abi,
				this.address
			);
			this.contract.Farm_contract = new this.global.web3.eth.Contract(
				this.onionabi.Farm_abi,
				this.onion.Farm
			);
			this.init();
		},
	},
	created() {
		this.soltnum = this.$route.query.num;
		this.address = this.$route.query.address;
		this.current = this.$route.query.current;
		this.allcords = this.$route.query.allcords;
		console.log(this.allcords, 'all', this.current);
		this.time = this.$route.query.time;
		console.log(this.time, 'time');
	},
	methods: {
		init() {
			// this.contract.Farm_contract.methods
			//   .getRecords(this.global.account, this.address, this.soltnum)
			//   .call()
			//   .then((res) => {
			//     this.$nextTick(() => {
			//       this.time = res.records;
			//       console.log(this.time, "res");
			//     });
			//     // res.records.map((record, index) => {
			//     //   this.$set(this.time[i], index, record.expiration);
			//     //   if (record.expiration > record.freeze) {
			//     //     let end =
			//     //       record.expiration > newDate ? newDate : record.expiration;
			//     //     sss += (end - record.freeze) * record.outRate;
			//     //     this.$set(this.rewards, i, sss);
			//     //     this.$set(this.guoqitime, i, end);
			//     //   }
			//     // });
			//   })
			//   .catch((err) => {
			//     console.log(err);
			//   });
		},
		async jihuo() {
			this.isLoading = true;
			console.log(
				this.global.account,
				this.onion.Farm,
				this.result[0],
				1,
				this.global.web3.eth.abi.encodeParameter('uint256', this.soltnum),
				'11 222'
			);
			this.contract.ERC1155_contract.methods
				.safeTransferFrom(
					this.global.account,
					this.onion.Farm,
					this.result[0],
					1,
					this.global.web3.eth.abi.encodeParameter('uint256', this.soltnum)
				)
				.send({
					from: this.global.account,
				})
				.then((res) => {
					console.log(res);
					this.isLoading = false;
					this.$notify({ type: 'success', message: 'SUCCESS' });
				})
				.catch((err) => {
					console.log(err);
					this.isLoading = false;
					this.$notify({ type: 'warning', message: err.message });
				});
		},
	},
};
</script>

<style lang="scss">
.kapian {
	margin-top: 5vh;
	.box2 {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		.child1 {
			width: 45%;
			height: 217px;
			background: rgba(255, 255, 255, 0.1);
			border-radius: 10px;
			margin-bottom: 40px;
			box-sizing: border-box;
			padding: 10px;

			// -webkit-filter: grayscale(100%);
			// -moz-filter: grayscale(100%);
			// -ms-filter: grayscale(100%);
			// -o-filter: grayscale(100%);
			// filter: grayscale(100%);
			// filter: gray;

			.van-checkbox {
				font-size: 15px;
				font-family: PingFang SC;
				font-weight: bold;
				color: rgba($color: #000000, $alpha: 0.74);
				justify-content: space-between;
				.van-icon {
					border: 1px solid #000;
				}
			}
			.son1 {
			}
		}
	}
	.box3 {
		width: 340px;
		height: 45px;
		background: #821973;
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 40px;
	}
	.van-checkbox {
		.van-checkbox__label {
			color: #000 !important;
		}
	}
}
</style>
