<template>
  <div class="nftPage">
    <div class="notbox0">
      <img
        class="child1 animated container"
        src="../../assets/ntf_box/manghe.png"
      />
    </div>
    <div class="nftbox1" ref="nftbox1">
      <div class="box1">
        <div
          class="child1"
          v-for="(item, index) in alldata"
          :key="item.name"
          @click="foucs(index)"
          v-show="$i18n.locale == 'zh'"
        >
          {{ item.cnname }}
        </div>
        <div
          class="child1"
          v-for="(item, index) in alldata"
          :key="item.cnname"
          @click="foucs(index)"
          v-show="$i18n.locale == 'en'"
        >
          {{ item.name }}
        </div>
      </div>
      <div
        class="box2"
        v-for="(item, index) in alldata"
        :key="index"
        v-show="index == flag"
      >
        <div class="child1">
          <div class="son1">{{ $t("head.bb1") }}</div>
          <div class="son2">{{ item.price }}<span class="sonn">OMT</span></div>
        </div>
        <div class="child2">
          <div class="son1">{{ $t("head.bb2") }}</div>
          <div class="son2">
            <div class="sonn1" @click="reduce(index)"></div>
            <div class="sonn2">{{ item.num }}</div>
            <div class="sonn3" @click="add(index)"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="nftbox2">
      <div class="child1">{{ $t("head.bb3") }}</div>
      <div class="child2">
        {{ $t("head.bb4") }}
      </div>
      <div class="child1">{{ $t("head.bb5") }}</div>
      <div class="child2">{{ $t("head.bb6") }}</div>
      <div class="child1">{{ $t("head.bb7") }}</div>
      <div class="child2">{{ $t("head.bb8") }}</div>
    </div>

    <div class="nftbox3">
      <div class="box2">
        <div class="child1">
          <div class="son1">
            <span class="sonn1">{{ $t("head.bb9") }}/ </span
            ><span class="sonn2">
              {{
                new $Decimal(alldata[flag].num)
                  .mul(new $Decimal(alldata[flag].price))
                  .toNumber()
              }}</span
            ><span class="sonn3">OMT</span>
          </div>
          <div class="son2">
            <span class="sonn1">{{ $t("head.bb10") }} </span>
            <span class="sonn2"
              >{{ (global.balanceOf / 1e18).toFixed(2) }} OMT</span
            >
          </div>
        </div>
        <div class="child2" @click="shopbox()">
          <div class="son1">{{ $t("head.bb11") }}</div>
        </div>
      </div>
    </div>
    <div class="mask" v-if="isBuy">
      <img
        class="child1 animate content"
        src="../../assets/ntf_box/manghe.png"
        v-if="isLoading"
      />
      <!-- <img src="../../assets/ntf_box/manghe-open.png" v-else class="open" /> -->
      <div class="child2" v-else>
        <div
          v-for="(item, index) in cords"
          :key="index"
          class="open son1"
          v-show="item != 0"
          :style="{
            backgroundImage: `url(${img[current][index].img})`,
            backgroundSize: '100% 100%',
          }"
        >
          <div class="sonn2">
            {{ img[current][index].title }}
          </div>
          <div class="sonn3">
            <div class="sonnchild">X{{ item }}</div>
          </div>
        </div>
      </div>
      <div class="btns" v-if="!isLoading">
        <div class="btn" @click="toJihuo()">{{ $t("head.bb12") }}</div>
        <div
          class="btn"
          @click="
            isBuy = false;
            isLoading = false;
          "
        >
          {{ $t("head.bb13") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "../../mixins/index";
import bg1 from "../../assets/ntf_box/ntfbox1.png";
import bg2 from "../../assets/ntf_box/ntfbox2.png";
import bg3 from "../../assets/ntf_box/ntfbox3.png";
import sb1 from "../../assets/nft_mine/kapian1.png";
import sb2 from "../../assets/nft_mine/kapian2.png";
import sb3 from "../../assets/nft_mine/kapian3.png";
import sb4 from "../../assets/nft_mine/kapian4.png";
import cy1 from "../../assets/nft_mine/kapian5.png";
import cy2 from "../../assets/nft_mine/kapian6.png";
import cy3 from "../../assets/nft_mine/kapian7.png";
import cy4 from "../../assets/nft_mine/kapian8.png";
import zy1 from "../../assets/nft_mine/kapian9.png";
import zy2 from "../../assets/nft_mine/kapian10.png";
import zy3 from "../../assets/nft_mine/kapian11.png";
import zy4 from "../../assets/nft_mine/kapian12.png";
export default {
  data() {
    return {
      current: 0,
      flag: "0",
      num: 1,
      title: "",
      price: "",
      token: "",
      isLoading: false,
      isBuy: false,
      cords: [],
    };
  },
  mixins: [mixins],
  watch: {
    "global.account"() {
      this.contract.ERC1155_contract = new this.global.web3.eth.Contract(
        this.onionabi.ERC1155_abi,
        this.onion.erc1155[this.flag]
      );
      this.contract.Shop_contract = new this.global.web3.eth.Contract(
        this.onionabi.Shop_abi,
        this.onion.Shop
      );
      this.contract.Omt_contract = new this.global.web3.eth.Contract(
        this.onionabi.Omt_abi,
        this.onion.Omt
      );
      this.Balanceof();
    },
  },
  computed: {
    img() {
      return [
        [
          { img: sb1, title: this.$t("head.cc1") },
          { img: sb2, title: this.$t("head.cc2") },
          { img: sb3, title: this.$t("head.cc3") },
          { img: sb4, title: this.$t("head.cc4") },
        ],
        [
          { img: cy1, title: this.$t("head.cc5") },
          { img: cy2, title: this.$t("head.cc6") },
          { img: cy3, title: this.$t("head.cc7") },
          { img: cy4, title: this.$t("head.cc8") },
        ],
        [
          { img: zy1, title: this.$t("head.cc9") },
          { img: zy2, title: this.$t("head.cc10") },
          { img: zy3, title: this.$t("head.cc11") },
          { img: zy4, title: this.$t("head.cc12") },
        ],
      ];
    },
  },
  methods: {
    foucs(index) {
      this.flag = index;
      switch (index) {
        case 0:
          this.$refs.nftbox1.style.background = `url(${bg1})`;
          this.$refs.nftbox1.style.backgroundSize = `100% 100%`;
          break;
        case 1:
          this.$refs.nftbox1.style.background = `url(${bg2})`;
          this.$refs.nftbox1.style.backgroundSize = `100% 100%`;
          break;
        case 2:
          this.$refs.nftbox1.style.background = `url(${bg3})`;
          this.$refs.nftbox1.style.backgroundSize = `100% 100%`;
          break;
      }
    },

    // 购买盲盒
    shopbox() {
      const num1 = this.alldata[this.flag].num;
      const num2 = this.global.web3.utils.toBN(this.alldata[this.flag].price);
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t("head.jj1"),
      });
      this.contract.Omt_contract.methods
        .approve(
          this.onion.Shop,
          this.global.web3.utils.toWei(
            this.global.web3.utils.toBN(num1 * num2),
            "ether"
          )
        )
        .send({
          from: this.global.account,
        })
        .then((res) => {
          this.contract.Shop_contract.methods
            .blindBox(
              this.onion.erc1155[this.flag],
              this.alldata[this.flag].num,
              "0x"
            )
            .send({
              from: this.global.account,
              // value: this.global.web3.utils.toBN(
              //   new this.$Decimal(this.alldata[this.flag].num)
              //     .mul(new this.$Decimal(this.alldata[this.flag].price))
              //     .mul(new this.$Decimal(1e18))
              //     .toNumber()
              // ),
            })
            .then((res) => {
              this.$toast.clear();
              console.log(res.events.BlindBox.returnValues);
              this.cords = res.events.BlindBox.returnValues.amounts;
              this.$notify({ type: "success", message: "SUCCESS" });
              this.isBuy = true;
              this.isLoading = true;
              const timer = setInterval(() => {
                this.isLoading = false;
                clearInterval(timer);
              }, 3000);
            })
            .catch((err) => {
              this.$toast.clear();
              console.log(err);
              this.$notify({ type: "warning", message: err.message });
              this.isBuy = false;
            });
        }).catch(err=>{
              this.$toast.clear();
        });
    },

    add(index) {
      this.alldata[index].num++;
      this.num = this.alldata[index].num;
    },

    reduce(index) {
      if (this.alldata[index].num == 1) {
        this.alldata[index].num = 1;
      } else {
        this.alldata[index].num--;
      }
      this.num = this.alldata[index].num;
    },

    toJihuo() {
      this.$router.push({
        path: "/mine",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.nftPage {
  .notbox0 {
    width: 100%;
    background: url("../../assets/nft_mine/bg1.png") no-repeat;
    background-size: 120% 120%;
    background-position-x: -30px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .child1 {
      margin-top: 50px;
      width: 230px;
    }
    .animated {
      animation-duration: 2s; /*动画时间*/
      animation-fill-mode: both; /*播放后的状态*/
    }

    .animated {
      animation-iteration-count: infinite; /*动作循环的次数：infinite 无限循环*/
    }

    .container {
      color: white;
      text-align: center;
      padding: 0;
    }

    .container:hover {
      animation-name: container; /*动画的名称*/
      transform-origin: center bottom; /*设置动画旋转元素的基点为：居中靠下*/
      cursor: pointer;
    }

    @keyframes container {
      from {
        -webkit-transform: none;
        transform: none;
      }
      15% {
        -webkit-transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, -5deg);
      }
      30% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 3deg);
      }
      45% {
        -webkit-transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, -3deg);
      }
      60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
      }
      75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
      }
      to {
        -webkit-transform: none;
        transform: none;
      }
    }
  }
  .nftbox1 {
    height: 179px;
    background: url("../../assets/ntf_box/ntfbox1.png");
    background-size: 100% 100%;
    margin-bottom: 15px;
    position: relative;
    top: -50px;
    .box1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      .child1 {
        flex: 1;
        font-size: 12px;
        font-weight: bold;
        font-family: PingFang SC;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .box2 {
      box-sizing: border-box;
      padding: 20px 30px;
      .child1 {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .son1 {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          opacity: 0.7;
          display: flex;
          align-items: center;
        }
        .son2 {
          font-size: 30px;
          font-family: STHupo;
          font-weight: 400;
          .sonn {
            font-size: 12px;
            font-family: STHupo;
            font-weight: 400;
            opacity: 0.33;
            margin-left: 3px;
          }
        }
      }
      .child2 {
        display: flex;
        justify-content: space-between;
        .son1 {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          opacity: 0.7;
          display: flex;
          align-items: center;
        }
        .son2 {
          width: 108px;
          height: 48px;
          background: url("../../assets/ntf_box/bg1.png");
          background-size: 100% 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          .sonn1 {
            width: 25px;
            height: 25px;
            background: url("../../assets/ntf_box/replace.png") no-repeat;
            background-size: 100% 100%;
          }
          .sonn2 {
            font-size: 30px;
            font-family: STHupo;
            font-weight: 400;
          }
          .sonn3 {
            width: 25px;
            height: 25px;
            background: url("../../assets/ntf_box/add.png") no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
  .nftbox2 {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    text-align: left;
    box-sizing: border-box;
    padding: 20px 30px;
    margin-bottom: 34px;
    position: relative;
    top: -50px;
    .child1 {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 500;
      margin-bottom: 5px;
    }
    .child2 {
      font-size: 10px;
      font-family: PingFang SC;
      font-weight: 400;
      opacity: 0.4;
      margin-bottom: 15px;
    }
  }

  .nftbox3 {
    position: relative;
    top: -20px;
    .box2 {
      display: flex;
      justify-content: space-between;
      .child1 {
        text-align: left;
        .son1 {
          font-family: STHupo;
          font-weight: 400;
          margin-bottom: 5px;
          .sonn1 {
            font-size: 9px;
            font-family: PingFang SC;
            font-weight: 400;
            opacity: 0.5;
          }
          .sonn2 {
            font-size: 24x;
          }
          .sonn3 {
            font-size: 12px;
          }
        }
        .son2 {
          .sonn1 {
            font-size: 9px;
            font-family: PingFang SC;
            font-weight: 400;
            opacity: 0.5;
          }
          .sonn2 {
            font-size: 12px;
            font-family: STHupo;
            font-weight: 400;
          }
        }
      }
      .child2 {
        width: 170px;
        height: 45px;
        background: #821973;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        .son1 {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
        }
      }
    }
  }

  .mask {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 99999;
    overflow: hidden;
    .child2 {
      margin-top: 50px;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-bottom: 20px;
      .son1 {
        width: 160px;
        height: 230px;
        background: rgba($color: #2e0929, $alpha: 0.65);
        border-radius: 10px;
        box-sizing: border-box;
        padding: 13px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .sonn1 {
          width: 100%;
          margin-bottom: 10px;
        }
        .sonn2 {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: bold;
          color: rgba(0, 0, 0, 1);
        }
        .sonn3 {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: bold;
          color: rgba(0, 0, 0, 1);
          .sonnchild {
            width: 30px;
            height: 30px;
            border: 2px solid rgba($color: #000000, $alpha: 0.5);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    img {
      width: 230px;
      padding-top: 150px;
    }

    .btns {
      width: 300px;
      height: 40px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      .btn {
        width: 140px;
        height: 40px;
        background: #821973;
        border-radius: 10px;
        line-height: 40px;
        text-align: center;
        font-size: 18px;
        color: #fff;
      }
      .btn:nth-child(2) {
        background: #fff;
        color: #111;
      }
    }

    .animate {
      animation-duration: 1s; /*动画时间*/
      animation-fill-mode: both; /*播放后的状态*/
    }
    .animate {
      animation-iteration-count: infinite; /*动作循环的次数：infinite 无限循环*/
    }
    .content {
      animation-name: content; /*动画的名称*/
      transform-origin: center bottom; /*设置动画旋转元素的基点为：居中靠下*/
      cursor: pointer;
    }

    @keyframes content {
      from {
        -webkit-transform: none;
        transform: none;
      }
      15% {
        -webkit-transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, -5deg);
      }
      30% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 3deg);
      }
      45% {
        -webkit-transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, -3deg);
      }
      60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
      }
      75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
      }
      to {
        -webkit-transform: none;
        transform: none;
      }
    }
  }
}
</style>