<template>
	<div class="minePage">
		<div class="container">
			<div class="tabbox">
				<van-swipe class="my-swipe" :show-indicators="false" @change="onChange">
					<van-swipe-item v-for="(item, index) in 3" :key="index">
						<div class="mybox1" @click="claim()">{{ $t('head.ee1') }}</div>
						<div class="mybox2">
							<div class="mychild1">
								<div class="myson1">{{ $t('head.ee2') }}</div>
								<div class="myson2">
									{{ (rewards[0] / 1e18).toFixed(2) }} <span>omt</span>
								</div>
							</div>
							<div class="mychild2">
								<div class="myson1">{{ $t('head.ee3') }}</div>
								<div class="myson2">{{ balance }} <span>omt</span></div>
							</div>
						</div>
					</van-swipe-item>
				</van-swipe>
			</div>
			<div class="tabbox1">
				<div class="mybox1">{{ $t(xilie) }}</div>
				<div class="mybox2"><div class="mychild1" ref="jindu"></div></div>
			</div>
			<!-- <div class="tab">
        <div
          class="tab_li"
          :class="{ current: current === 0 }"
          @click="changeTab(0)"
        >
          上班系列
          <div class="dot"></div>
        </div>
        <div
          class="tab_li"
          :class="{ current: current === 1 }"
          @click="changeTab(1)"
        >
          创业系列
          <div class="dot"></div>
        </div>
        <div
          class="tab_li"
          :class="{ current: current === 2 }"
          @click="changeTab(2)"
        >
          财务自由
          <div class="dot"></div>
        </div>
      </div>
      <div class="banner">
        <div class="banner_left">
          <div class="li">
            <div class="th">已赚取:</div>
            <div class="td">{{ rewards[0] / 1e18 }} <span>omt</span></div>
          </div>
          <div class="li">
            <div class="th">钱包余额:</div>
            <div class="td">{{ balance }} <span>omt</span></div>
          </div>
          <div class="btn" @click="claim()">收取</div>
        </div>
      </div> -->

			<!-- 钱包里的卡无数据 -->
			<div class="conBox1">
				<div class="topVue">
					<div class="title">{{ $t('head.ee4') }}</div>
					<!-- <img src="../../assets/qrcode.png" /> -->
				</div>
				<div
					class="box1"
					v-if="
						allcords[0] != 0 ||
						allcords[1] != 0 ||
						allcords[2] != 0 ||
						allcords[3] != 0 ||
						allcords[4] != 0
					"
				>
					<div class="child1"></div>
					<div class="child2">
						<swiper class="mySwiper" :options="swiperOption" ref="mySwiper">
							<swiper-slide
								v-for="(item, index) in allcords"
								:key="index"
								v-show="item != 0"
							>
								<div class="son1" v-if="index == 0" :style="aaa">
									<div class="sonn2">{{ $t('head.ee5') }}</div>
									<div class="sonn3">
										<div class="sonnchild">X{{ allcords[index] }}</div>
									</div>
								</div>
								<div
									class="son1"
									v-if="index != 0"
									@click="
										blockwallet(
											img[current][index - 1].img1,
											$t(img[current][index - 1].title)
										)
									"
									:style="{
										backgroundImage: `url(${img[current][index - 1].img})`,
										backgroundSize: '100% 100%',
									}"
								>
									<div class="sonn2" v-if="index != 0">
										{{ $t(img[current][index - 1].title) }}
									</div>
									<div class="sonn3">
										<div class="sonnchild">X{{ allcords[index] }}</div>
									</div>
								</div>
							</swiper-slide>
						</swiper>
					</div>
				</div>
				<div class="box2" v-else>
					<img src="../../assets/nodata.png" class="nodata" />
					<div class="goLink" @click="gorouter('/nft')">
						{{ $t('head.ee6') }}
					</div>
				</div>
			</div>

			<!-- 激活挖矿的卡无数据 -->
			<div class="conBox">
				<div class="topVue">
					<div class="title">{{ $t('head.ee7') }}</div>
				</div>
				<div v-show="allsolt == 0" class="box2">
					<img src="../../assets/nodata.png" class="nodata" />
					<div class="goLink" @click="gorouter('/activate')">
						{{ $t('head.ee8') }}
					</div>
				</div>
				<div class="box11">
					<div
						class="box1"
						v-show="allsolt != 0"
						v-for="(item, index) in Number(allsolt)"
						:key="index"
					>
						<div class="child1">{{ $t('head.ee9') }}{{ index + 1 }}</div>
						<div class="child2">
							<!-- <swiper class="mySwiper" :options="swiperOption" ref="mySwiper"> -->
							<!-- 添加的图片 -->
							<!-- <swiper-slide> -->
							<div
								class="son1"
								@click="
									time[index] == 0
										? gorouter(
												'/card',
												index,
												onion.erc1155[current],
												current,
												allcords,
												time[index]
										  )
										: ''
								"
								:style="
									time[index] == 0
										? yinying(img[current][imgid[index]]?.img)
										: wuyinying(img[current][imgid[index]]?.img)
								"
							>
								<div class="sonn2">
									{{ $t(img[current][imgid[index]]?.title) }}
								</div>
								<img
									class="sonn4"
									src="../../assets/ntf_box/tianjia.png"
									alt=""
								/>
								<!-- <div class="sonn3">
									<van-count-down
										:time="(time[index] - Math.round(new Date() / 1000)) * 1000"
										v-if="time[index] != 0"
										@finish="finish()"
									>
										<template #default="timeData">
											<span class="block">{{ timeData.hours }}</span>
											<span class="colon">:</span>
											<span class="block">{{ timeData.minutes }}</span>
											<span class="colon">:</span>
											<span class="block">{{ timeData.seconds }}</span>
										</template>
									</van-count-down>
									
								</div> -->
							</div>
							<!-- </swiper-slide> -->
							<!-- </swiper> -->
						</div>
					</div>
				</div>
			</div>
			<div class="bottomVue">
				<div class="botton" @click="show = true">{{ $t('head.ee10') }}</div>
			</div>
			<van-popup v-model="show" position="bottom" round>
				<div class="content">
					<div class="topVue">
						<div class="title">{{ $t('head.ee11') }}</div>
						<img src="../../assets/close.png" @click="show = false" />
					</div>
					<div class="tip" v-show="jihuoka == 0">
						{{ $t('head.ee12') }}
					</div>
					<div class="tip" v-show="jihuoka != 0">
						<div class="son1" :style="aaa">
							<van-checkbox class="xuanzhong" v-model="checked">
								<div class="sonn2">{{ $t('head.ee5') }}</div>
							</van-checkbox>
							<div class="sonn3">
								<div class="sonnchild">
									{{ jihuoka }}
								</div>
							</div>
						</div>
					</div>
					<div class="btn" v-show="!checked" @click="gorouter('/activate')">
						{{ $t('head.ee8') }}
					</div>
					<div class="btn" v-show="checked" @click="jihuo(0, 1)">
						{{ $t('head.ee13') }}
					</div>
				</div>
			</van-popup>
			<van-popup class="wallet" v-model="show1" position="bottom" round>
				<div class="walletcontent" ref="wallet">
					<div class="walletbox1">{{ $t(xilie) }}</div>
					<div class="walletbox2">{{ walletname }}</div>
				</div>
			</van-popup>
		</div>
		<div v-if="isLoading">
			<Loading></Loading>
		</div>
	</div>
</template>

<script>
import mixins from '../../mixins/index';
import { Multicall } from '../../model/multicall';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import '../../../node_modules/vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css';
import { hexlify } from '@ethersproject/bytes';
import solt from '../../assets/nft_mine/solt.png';
import sb1 from '../../assets/nft_mine/kapian1.png';
import sb2 from '../../assets/nft_mine/kapian2.png';
import sb3 from '../../assets/nft_mine/kapian3.png';
import sb4 from '../../assets/nft_mine/kapian4.png';
import cy1 from '../../assets/nft_mine/kapian5.png';
import cy2 from '../../assets/nft_mine/kapian6.png';
import cy3 from '../../assets/nft_mine/kapian7.png';
import cy4 from '../../assets/nft_mine/kapian8.png';
import zy1 from '../../assets/nft_mine/kapian9.png';
import zy2 from '../../assets/nft_mine/kapian10.png';
import zy3 from '../../assets/nft_mine/kapian11.png';
import zy4 from '../../assets/nft_mine/kapian12.png';
import sb11 from '../../assets/nft_mine/sb11.png';
import sb22 from '../../assets/nft_mine/sb22.png';
import sb33 from '../../assets/nft_mine/sb33.png';
import sb44 from '../../assets/nft_mine/sb44.png';
import cy11 from '../../assets/nft_mine/cy11.png';
import cy22 from '../../assets/nft_mine/cy22.png';
import cy33 from '../../assets/nft_mine/cy33.png';
import cy44 from '../../assets/nft_mine/cy44.png';
import zy11 from '../../assets/nft_mine/zy11.png';
import zy22 from '../../assets/nft_mine/zy22.png';
import zy33 from '../../assets/nft_mine/zy33.png';
import zy44 from '../../assets/nft_mine/zy44.png';
import Loading from '../../components/Loading.vue';
export default {
	data() {
		return {
			imgid: [],
			walletname: '',
			delete1: false,
			slotimg: solt,
			aaa: {
				backgroundImage: `url('${solt}')`,
				backgroundSize: '100% 100%',
			},
			isLoading: false,
			balance: '0',
			current: 0,
			show: false,
			show1: false,
			allsolt: 0,
			jihuoka: 0,
			jihuoka1: 0,
			slotshow: true,
			checked: false,
			allcords: [0, 0, 0, 0, 0],
			checked1: [false, false, false, false],
			rewards: [0, 0, 0, 0],
			guoqitime: [0, 0, 0, 0],
			time: [],
			xilie: 'head.cc13',
			img: [
				[
					{ img: sb1, img1: sb11, title: 'head.cc1' },
					{ img: sb2, img1: sb22, title: 'head.cc2' },
					{ img: sb3, img1: sb33, title: 'head.cc3' },
					{ img: sb4, img1: sb44, title: 'head.cc4' },
				],
				[
					{ img: cy1, img1: cy11, title: 'head.cc5' },
					{ img: cy2, img1: cy22, title: 'head.cc6' },
					{ img: cy3, img1: cy33, title: 'head.cc7' },
					{ img: cy4, img1: cy44, title: 'head.cc8' },
				],
				[
					{ img: zy1, img1: zy11, title: 'head.cc9' },
					{ img: zy2, img1: zy22, title: 'head.cc10' },
					{ img: zy3, img1: zy33, title: 'head.cc11' },
					{ img: zy4, img1: zy44, title: 'head.cc12' },
				],
			],
			swiperOption: {
				grabCursor: true,
				slidesPerView: 1,
				centeredSlidesBounds: true,
				slidesOffsetBefore: 0,
				normalizeSlideIndex: false,
				slideToClickedSlide: true,

				spaceBetween: 15, // 在slide之间设置距离 也可以是百分比 10%
			},
		};
	},
	computed: {
		wuyinying() {
			return function (ss) {
				return {
					backgroundImage: `url(${ss})`,
					backgroundSize: '100% 100%',
				};
			};
		},
		yinying() {
			return function (ss) {
				return {
					backgroundImage: `url(${ss})`,
					backgroundSize: '100% 100%',
					'-webkit-filter': 'grayscale(100%)',
					'-moz-filter': 'grayscale(100%)',
					'-ms-filter': 'grayscale(100%)',
					'-o-filter': 'grayscale(100%)',
					filter: 'grayscale(100%)',
					filter: 'gray',
				};
			};
		},
	},
	components: { swiper, swiperSlide, Loading },
	mixins: [mixins],
	watch: {
		'global.account'() {
			this.contract.ERC1155_contract = new this.global.web3.eth.Contract(
				this.onionabi.ERC1155_abi,
				this.onion.erc1155[this.current]
			);
			this.contract.Farm_contract = new this.global.web3.eth.Contract(
				this.onionabi.Farm_abi,
				this.onion.Farm
			);
			this.contract.Omt_contract = new this.global.web3.eth.Contract(
				this.onionabi.Omt_abi,
				this.onion.Omt
			);
			this.init();
		},
		time() {
			console.log(this.time, 'time---');
		},
	},
	methods: {
		async init() {
			this.contract.Omt_contract.methods
				.balanceOf(this.global.account)
				.call()
				.then((res) => {
					console.log((res / 1e18).toFixed(2), 666666);
					this.balance = (res / 1e18).toFixed(2);
				});
			this.contract.ERC1155_contract.methods
				.balanceOfBatch(
					[
						this.global.account,
						this.global.account,
						this.global.account,
						this.global.account,
						this.global.account,
					],
					[0, 1, 2, 3, 4]
				)
				.call({
					from: this.global.account,
				})
				.then((res) => {
					this.allcords = res;
					console.log(this.allcords, 'this.allcords');
					this.jihuoka = res[0];
				});

			this.contract.Farm_contract.methods
				.getCount(this.global.account, this.onion.erc1155[this.current])
				.call()
				.then(async (res) => {
					this.$nextTick(async () => {
						console.log(res, 'res getCount');
						this.allsolt = res;
						let sss = 0;
						const newDate = Math.round(new Date() / 1000);
						for (var i = 0; i < res; i++) {
							await this.contract.Farm_contract.methods
								.getRecords(
									this.global.account,
									this.onion.erc1155[this.current],
									i
								)
								.call()
								.then((res) => {
									console.log(res.records, 'records');
									res.records.map((record, index) => {
										this.imgid.push(index);
										this.$set(this.time, index, record.expiration);
										if (record.expiration > record.freeze) {
											let end =
												record.expiration > newDate
													? newDate
													: record.expiration;
											sss += (end - record.freeze) * record.outRate;
											this.$set(this.rewards, i, sss);
											console.log(this.rewards, 'end');
											this.$set(this.guoqitime, i, end);
										}
									});
								})
								.catch((err) => {
									console.log(err, 'get records');
								});
							// await this.contract.Farm_contract.methods
							// 	.getRecords(this.global.account, this.onion.erc1155[this.current])
							// 	.call()
							// 	.then((res) => {
							// 		console.log(res, 'res11111111111');
							// 		res.map((record, index) => {
							// 			this.imgid.push(record.tokenId);
							// 			this.$set(this.time, index, record.expiration);
							// 			if (record.expiration > record.freeze) {
							// 				let end =
							// 					record.expiration > newDate ? newDate : record.expiration;
							// 				sss += (end - record.freeze) * record.outRate;
							// 				this.$set(this.rewards, i, sss);
							// 				console.log(this.rewards, 'end');
							// 				this.$set(this.guoqitime, i, end);
							// 			}
							// 		});
							// 	})
							// 	.catch((err) => {
							// 		console.log(err, 'getRecords');
							// 	});
						}
					});
				});
		},

		// 收取
		claim() {
			if (this.isLoading) return;
			this.isLoading = true;
			console.log(
				'harvest all',
				this.global.account,
				this.onion.erc1155[this.current],
				this.allsolt
			);
			this.contract.Farm_contract.methods
				.harvestAll(
					this.global.account,
					this.onion.erc1155[this.current],
					this.allsolt
				)
				.send({ from: this.global.account })
				.then((res) => {
					this.isLoading = false;
					this.$notify({ type: 'success', message: 'SUCCESS' });
					this.$router.go(0);
				})
				.catch((err) => {
					console.log(err);
					this.isLoading = false;
					this.$notify({ type: 'warning', message: err.message });
				});
			// const multicall = new Multicall(this.onion.multicall, this.global.web3);
			// const ids = Array(this.allsolt).fill(0);
			// const tokens = ids.map((item, index) => {
			// 	console.log(item);
			// 	const data = {
			// 		sends: this.contract.Farm_contract.methods.harvest(
			// 			this.global.account,
			// 			this.onion.erc1155[this.current],
			// 			index
			// 		),
			// 		address: this.onion.Farm,
			// 	};
			// 	return data;
			// });
			// console.log(tokens);
			// multicall
			// 	.aggregateSend(tokens, this.global.account)
			// 	.then((res) => {
			// 		this.isLoading = false;
			// 		this.$notify({ type: 'success', message: 'SUCCESS' });
			// 		this.$router.go(0);
			// 	})
			// 	.catch((err) => {
			// 		console.log(err);
			// 		this.isLoading = false;
			// 		this.$notify({ type: 'warning', message: err.message });
			// 	});
		},

		blockwallet(img, str) {
			console.log(str);
			this.walletname = str;
			this.show1 = true;
			this.$nextTick(() => {
				this.$refs.wallet.style.height = '450px';
				this.$refs.wallet.style.background = `url(${img}) no-repeat`;
				this.$refs.wallet.style.backgroundSize = `100% 100%`;
			});
		},

		// 激活
		jihuo(index, num) {
			if (this.isLoading) return;
			this.isLoading = true;
			this.contract.ERC1155_contract.methods
				.safeTransferFrom(
					this.global.account,
					this.onion.Farm,
					index,
					num,
					this.global.web3.eth.abi.encodeParameter('uint256', 0)
				)
				.send({
					from: this.global.account,
				})
				.then((res) => {
					console.log(res);
					this.$notify({ type: 'success', message: 'SUCCESS' });
					this.isLoading = false;
					this.$router.go(0);
				})
				.catch((err) => {
					console.log(err);
					this.isLoading = false;
					this.$notify({ type: 'warning', message: err.message });
				});
		},
		gorouter(path, num, address, current, allcords, time) {
			this.$router.push({
				path: path,
				query: {
					num,
					address,
					current,
					allcords,
					time,
				},
			});
		},
		onChange(index) {
			console.log('当前 Swipe 索引：' + index);
			this.current = index;
			this.allcords = [0, 0, 0, 0, 0];
			this.contract.ERC1155_contract = new this.global.web3.eth.Contract(
				this.onionabi.ERC1155_abi,
				this.onion.erc1155[this.current]
			);
			this.init();
			switch (index) {
				case 0:
					this.xilie = 'head.cc13';
					this.$refs.jindu.style.marginLeft = '0px';
					break;
				case 1:
					this.xilie = 'head.cc14';
					this.$refs.jindu.style.marginLeft = '33%';
					break;
				case 2:
					this.xilie = 'head.cc15';
					this.$refs.jindu.style.marginLeft = '66%';
					break;
			}
		},
		finish() {
			this.delete1 = true;
		},
		remove(index, index1) {
			this.isLoading = true;
			console.log(this.current, index, index1, '6666666');
			const num = index1 * 1 + 1;
			this.contract.Farm_contract.methods
				.remove(this.onion.erc1155[this.current], index, num)
				.send({
					from: this.global.account,
				})
				.then((res) => {
					this.isLoading = false;
					this.$notify({ type: 'success', message: 'SUCCESS' });
					this.$router.go(0);
				})
				.catch((err) => {
					this.isLoading = false;
					this.$notify({ type: 'warning', message: err.message });
				});
		},
		changeTab(index) {
			this.current = index;
			this.contract.ERC1155_contract = new this.global.web3.eth.Contract(
				this.onionabi.ERC1155_abi,
				this.onion.erc1155[this.current]
			);
			this.init();
		},
	},
};
</script>

<style lang="scss">
.container {
	padding-bottom: 88px;
	.tabbox {
		width: 90%;
		margin-left: 10%;
		height: 250px;
		background: url('../../assets/nft_mine/bg0.png') no-repeat;
		background-size: 100% 100%;
		margin-bottom: 20px;
		.my-swipe {
			.van-swipe-item {
				height: 250px;
				color: #fff;
				font-size: 20px;
				text-align: center;
				overflow: hidden;
				.mybox1 {
					font-size: 16px;
					font-family: PingFang SC;
					font-weight: 500;
					color: #ffffff;
					margin: 112px 0 0 38%;
				}
				.mybox2 {
					text-align: left;
					display: flex;
					align-items: center;
					margin: 10% 0 0 10%;
					.mychild1 {
						.myson1 {
							font-size: 15px;
							font-family: PingFang SC;
							font-weight: 400;
							color: rgba($color: #ffffff, $alpha: 0.4);
						}
						.myson2 {
							font-size: 21px;
							font-family: STHupo;
							font-weight: 400;
							span {
								font-size: 12px;
							}
						}
					}
					.mychild2 {
						margin-left: 15%;
						.myson1 {
							font-size: 15px;
							font-family: PingFang SC;
							font-weight: 400;
							color: rgba($color: #ffffff, $alpha: 0.4);
						}
						.myson2 {
							font-size: 21px;
							font-family: STHupo;
							font-weight: 400;
							span {
								font-size: 12px;
							}
						}
					}
				}
			}
		}
	}
	.tabbox1 {
		overflow: hidden;
		.mybox1 {
			font-size: 18px;
		}
		.mybox2 {
			margin: 10px auto;
			width: 150px;
			height: 3px;
			background: rgba($color: #ffffff, $alpha: 0.25);
			.mychild1 {
				width: 33%;
				height: 3px;
				background: #ffffff;
			}
		}
	}
	.content {
		width: 100%;
		// height: 250px;
		.topVue {
			height: 66px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 18px;
			.title {
				font-size: 18px;
				font-weight: 700;
				color: #000000;
			}
			img {
				width: 14px;
				height: 14px;
			}
		}
		.tip {
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: 500;
			color: rgba($color: #000000, $alpha: 0.5);
			padding-top: 30px;
			.son1 {
				margin: 0 auto;
				width: 162px;
				height: 230px;
				background: rgba($color: #2e0929, $alpha: 0);
				border-radius: 10px;
				box-sizing: border-box;
				padding: 13px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				.xuanzhong {
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					.van-checkbox__icon {
						.van-icon {
							border: 1px solid #000;
						}
					}
					.sonn2 {
						font-size: 15px;
						font-family: PingFang SC;
						font-weight: bold;
						color: rgba(0, 0, 0, 1);
					}
				}
				.sonn1 {
					width: 100%;
					margin-bottom: 10px;
				}
				.sonn3 {
					font-size: 15px;
					font-family: PingFang SC;
					font-weight: bold;
					color: rgba(0, 0, 0, 1);
					.sonnchild {
						width: 20px;
						height: 20px;
						border: 2px solid rgba($color: #000000, $alpha: 0.5);
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
			}
		}
		.btn {
			width: 320px;
			height: 40px;
			background: #821973;
			border-radius: 20px;
			line-height: 40px;
			text-align: center;
			font-weight: 700;
			margin: 30px auto 40px;
		}
	}
	.bottomVue {
		width: 100%;
		height: 80px;
		background: #000;
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 1000;
		// padding: 0 30px;
		.botton {
			width: 320px;
			height: 40px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			height: 40px;
			background: #821973;
			border-radius: 20px;
			line-height: 40px;
			font-weight: 600;
			font-size: 16px;
		}
	}
	.wallet {
		background: rgba($color: #000000, $alpha: 0);
		.walletcontent {
			color: #000;
			text-align: left;
			box-sizing: border-box;
			padding: 20px;
			.walletbox1 {
				font-size: 12px;
				font-family: PingFang SC;
				font-weight: 400;
				color: rgba($color: #000000, $alpha: 0.5);
				margin-bottom: 15px;
			}
			.walletbox2 {
				font-size: 18px;
				font-family: PingFang SC;
				font-weight: 800;
				color: #000000;
			}
		}
	}
}

.tab {
	display: flex;
	justify-content: space-around;
	height: 100px;
	align-items: center;
	.tab_li {
		font-size: 16px;
		font-weight: 500;
		color: rgba($color: #ffffff, $alpha: 0.5);
		height: 100px;
		line-height: 100px;
		position: relative;
	}
	.current {
		font-size: 16px;
		color: rgba($color: #ffffff, $alpha: 1);
		.dot {
			width: 50px;
			height: 5px;
			background: rgba($color: #821973, $alpha: 0.6);
			left: 50%;
			position: absolute;
			bottom: 40px;
			transform: translate(-50%);
			z-index: 2;
			border-radius: 2px;
		}
	}
}

.banner {
	height: 160px;
	background: rgba(255, 255, 255, 0.1);
	border-radius: 20px;
	position: relative;
	.banner_left {
		padding: 16px 0 50px 30px;
		.btn {
			width: 80px;
			height: 30px;
			background: #9f8aec;
			border-radius: 20px;
			text-align: center;
			line-height: 32px;
			position: absolute;
			bottom: -15px;
			font-weight: 600;
			color: #ffffff;
			font-size: 15px;
		}
		.li {
			text-align: left;
			margin-bottom: 15px;

			.th {
				font-size: 12px;
				font-weight: bold;
				color: #ffffff;
			}
			.td {
				font-size: 20px;
				font-weight: 700;
				color: #ffffff;
				padding-top: 6px;
				span {
					font-size: 12px;
					font-family: STHupo;
					font-weight: 400;
					color: #ffffff;
				}
			}
		}
	}
}

.conBox {
	.topVue {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;
		.title {
			font-weight: 800;
			color: #ffffff;
			font-size: 18px;
		}
		img {
			width: 16px;
			height: 16px;
		}
	}
	.box11 {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.box1 {
		width: 47%;
		min-height: 186px;
		text-align: left;
		.child1 {
			margin-bottom: 10px;
		}
		.child2 {
			// display: flex;
			// justify-content: space-between;
			// align-items: center;
			overflow: hidden;
			.son1 {
				// background-size: 100% 100% !important;
				// background-repeat: no-repeat;
				// height: 167px;
				// width: 104px;
				height: 230px;
				// background: rgba($color: #2e0929, $alpha: 0.65);
				border-radius: 10px;
				box-sizing: border-box;
				padding: 8px;
				text-align: center;
				color: rgba(0, 0, 0, 1);
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				box-sizing: border-box;
				padding: 15px;
				// .sonn1 {
				//   width: 100%;
				//   margin-bottom: 10px;
				// }
				.sonn2 {
					font-size: 15px;
					font-family: PingFang SC;
					font-weight: 400;
					margin-bottom: 5px;
				}
				.sonn3 {
					display: flex;
					align-items: center;
					.delete {
						margin-left: 10px;
					}
					.colon {
						display: inline-block;
						margin: 0 4px;
						color: #ee0a24;
					}
					.block {
						display: inline-block;
						width: 15px;
						font-size: 12px;
						font-family: STHupo;
						font-weight: bold;
						color: #000000;
						font-size: 12px;
						text-align: center;
						background: rgba($color: #ebecf0, $alpha: 0.4);
						box-shadow: -1px 1px 2px 0px rgba(0, 0, 0, 0.4),
							1px -1px 1px 0px rgba(255, 255, 255, 0.65),
							-2px 2px 3px 0px rgba(0, 0, 0, 0.07),
							3px -4px 10px 0px rgba($color: #fff, $alpha: 0.3);
						border-radius: 4px;
					}
				}

				.sonn4 {
					margin-bottom: 45px;
					opacity: 0.7;
				}
			}
			.slide {
				width: 150px !important;
				height: 150px;
				background: red;
			}
			.slide:nth-child(2n) {
				background: green;
			}
			.mySwiper {
				.swiper-wrapper {
					.swiper-slide {
					}
				}
			}
		}
	}
	.nodata {
		width: 168px;
		height: 140px;
		margin: 30px auto 0;
	}
	.goLink {
		text-align: center;
		font-size: 16px;
		text-decoration: underline;
		color: rgba($color: #ffffff, $alpha: 0.6);
		line-height: 46px;
	}
}
.conBox1 {
	margin-top: 50px;
	margin-bottom: 30px;
	.topVue {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;
		.title {
			font-weight: 800;
			color: #ffffff;
			font-size: 18px;
		}
		img {
			width: 16px;
			height: 16px;
		}
	}
	.box1 {
		min-height: 186px;
		text-align: left;
		.child2 {
			// display: flex;
			// justify-content: space-between;
			// align-items: center;
			overflow: hidden;
			.slide {
				width: 150px !important;
				height: 150px;
				background: red;
			}
			.slide:nth-child(2n) {
				background: green;
			}
			.mySwiper {
				.swiper-wrapper {
					.swiper-slide {
						width: 162px !important;
						.son1 {
							// background-size: 100% 100% !important;
							// background-repeat: no-repeat;
							// height: 167px;
							// width: 104px;
							height: 230px;
							background: rgba($color: #2e0929, $alpha: 0.65);
							border-radius: 10px;
							box-sizing: border-box;
							padding: 13px;
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							align-items: center;
							.sonn1 {
								width: 100%;
								margin-bottom: 10px;
							}
							.sonn2 {
								font-size: 15px;
								font-family: PingFang SC;
								font-weight: bold;
								color: rgba(0, 0, 0, 1);
							}
							.sonn3 {
								font-size: 15px;
								font-family: PingFang SC;
								font-weight: bold;
								color: rgba(0, 0, 0, 1);
								.sonnchild {
									width: 30px;
									height: 30px;
									border: 2px solid rgba($color: #000000, $alpha: 0.5);
									border-radius: 50%;
									display: flex;
									justify-content: center;
									align-items: center;
								}
							}
						}
					}
				}
			}
		}
	}
	.nodata {
		width: 168px;
		height: 140px;
		margin: 30px auto 0;
	}
	.goLink {
		text-align: center;
		font-size: 16px;
		text-decoration: underline;
		color: rgba($color: #ffffff, $alpha: 0.6);
		line-height: 46px;
	}
}
.swiper-container {
	// left: -86px;
	.swiper-wrapper {
	}
}
</style>
