import {
    ethers
} from 'ethers'
import ABI from './Multicall.json'

export class Multicall {
    constructor(contract, web3) {
        this.contract = contract;
        this.web3 = web3;
        this.multicall = new web3.eth.Contract(
            ABI,
            contract
        );
    }

    async aggregateCall(calls) {
        const encodeCalls = calls.map((k, v) => {
            return [k.address, k.calls.encodeABI()]
        })
        const data = await this.multicall.methods.aggregate(encodeCalls).call();
        return data.returnData.map((res, i) => {
            const outputs = calls[i].calls._method.outputs.map((value) => this.buildOutPuts(value))
            return this.web3.eth.abi.decodeParameters(outputs, res);
        })
    }

    buildOutPuts(output) {
        return {
            "type": output.type,
            "name": output.name
        }
    }

    async aggregateSend(calls, account) {
        console.log(this.multicall)
        const encodeCalls = calls.map((k, v) => {
            return [k.address, k.sends.encodeABI()]
        })
        const data = await this.multicall.methods.aggregate(encodeCalls).send({
            from: account
        });
    }
}