<template>
  <div class="homePage">
    <div class="banner">
      <div class="box1" @click="show = true">{{ $t("head.aa1") }}</div>
      <van-popup
        class="box2"
        v-model="show"
        round
        position="bottom"
        closeable
        close-icon="close"
      >
        <div class="child1">{{ $t("head.aa2") }}</div>
        <div class="child2">
          {{ $t("head.aa3") }}
        </div>
        <div class="child3">
          {{ $t("head.aa4") }}
        </div>
      </van-popup>
    </div>
    <div class="item">
      <div class="box1"></div>
      <div class="box2">
        <div class="child1">{{ $t("head.aa5") }}</div>
        <div class="child2">
          <span class="son1" @click="plz(1)">{{ $t("head.aa6") }}</span>
          <span class="son1" @click="plz(2)">{{ $t("head.aa7") }}</span>
        </div>
        <div class="child3">
          <img class="son1" src="../assets/img1.png" alt="" />
          <div class="son2">
            {{ (pre1 / this.bilv).toFixed(0) / 1000 }}k <span>USD</span>
          </div>
        </div>
        <div class="child3">
          <img class="son1" src="../assets/img2.png" alt="" />
          <div class="son2">{{ pre1 / 1000 }}k <span>OMT</span></div>
        </div>
      </div>
    </div>
       
    <div class="item">
      <div class="box3">
        <div class="child1">LP TVL</div>
        <div class="child2">
          <span class="son1" @click="plz(3)">{{ $t("head.aa8") }}</span>
          <span class="son1" @click="plz(4)">{{ $t("head.aa9") }}</span>
        </div>
        <!-- <div class="child3">
          <img class="son1" src="../assets/img1.png" alt="" />
          <div class="son2">{{(allcordsprice/this.bilv).toFixed(4)}} <span>USD</span></div>
        </div> -->
        <div class="child3">
          <img class="son1" src="../assets/img2.png" alt="" />
          <div class="son2">{{ pre2 }} <span>USDT</span></div>
        </div>
      </div>
    </div>
    <div class="box1"></div>
  </div>
</template>

<script>
import mixin from "../mixins/index";
import { Multicall } from "../model/multicall";
import { Decimal } from "decimal.js";
export default {
  data() {
    return {
      show: false,
      contract11: [
        { contract1: undefined },
        { contract2: undefined },
        { contract3: undefined },
      ],
      bilv: 1,
      usdt1: 0,
      usdt2: "",
      allprice: 0,
      allcordsprice: 0,
      omtfeee: "",
      slotfeee: "",
      pre1: "",
      pre2: "",
    };
  },
  mixins: [mixin],
  watch: {
    "global.account"() {
      this.contract.ERC1155_contract = new this.global.web3.eth.Contract(
        this.onionabi.ERC1155_abi,
        this.onion.erc1155[this.current]
      );
      this.contract.Farm_contract = new this.global.web3.eth.Contract(
        this.onionabi.Farm_abi,
        this.onion.Farm
      );
      this.contract.Omt_contract = new this.global.web3.eth.Contract(
        this.onionabi.Omt_abi,
        this.onion.Omt
      );
      this.contract.USDT_contract = new this.global.web3.eth.Contract(
        this.onionabi.Omt_abi,
        this.onion.USDT
      );

      this.contract.jiaoyidui_contract = new this.global.web3.eth.Contract(
        this.onionabi.jiaoyidui_abi,
        this.onion.jiaoyidui
      );
      this.init();
    },
    time() {
      console.log(this.time);
    },
  },
  methods: {
    init() {
      this.contract.jiaoyidui_contract.methods
        .getReserves()
        .call()
        .then((res) => {
          console.log(res);
          const num = ((res._reserve0 / 1e18) * res._reserve1) / 1e18;
          this.bilv = res._reserve1 / 1e18 - num / (res._reserve0 / 1e18 + 1);
          const bili = 1 / this.bilv;
          console.log(bili);
        });
      const multicall = new Multicall(this.onion.multicall, this.global.web3);

      this.contract11 = this.contract11.map((item, index) => {
        item = new this.global.web3.eth.Contract(
          this.onionabi.ERC1155_abi,
          this.onion.erc1155[index]
        );

        const ids = Array(4).fill(0);
        const tokens = ids.map((item1, index1) => {
          const data = {
            calls: item.methods.totalSupply(index1 + 1),
            address: this.onion.erc1155[index],
          };
          return data;
        });
        multicall
          .aggregateCall(tokens)
          .then((res) => {
            res.map((item2, index2) => {
              // console.log(new Decimal(item2[0]).mul(new Decimal(this.alldata[index].price)).toNumber(),'item1');
              this.allprice += new Decimal(item2[0])
                .mul(new Decimal(this.alldata[index].price))
                .toNumber();
              this.pre1 = this.allprice;
              this.contract.Omt_contract.methods
                .balanceOf(this.onion.omtfee)
                .call()
                .then((res) => {
                  this.omtfeee = (this.allprice - res / 1e18) / 2;
                  if (this.omtfeee < 0) {
                    this.omtfee = 0;
                  }
                });
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });

      const token1 = this.contract11.map((item, index) => {
        item = new this.global.web3.eth.Contract(
          this.onionabi.ERC1155_abi,
          this.onion.erc1155[index]
        );
        const data = {
          calls: item.methods.totalSupply(0),
          address: this.onion.erc1155[index],
        };
        return data;
      });

      multicall
        .aggregateCall(token1)
        .then((res) => {
          res.map((item2, index2) => {
            this.allcordsprice += new Decimal(item2[0])
              .mul(new Decimal(this.alldata[index2].slotPrice))
              .toNumber();
          });
          this.pre2 = this.allcordsprice;
          this.contract.USDT_contract.methods
            .balanceOf(this.onion.slotfee)
            .call()
            .then((res) => {
              this.slotfeee = (this.allcordsprice - res / 1e18) / 2;
              if (this.slotfeee < 0) {
                this.slotfeee = 0;
              }
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    plz(index) {
      switch (index) {
        case 1:
          this.pre1 = this.allprice;
          break;
        case 2:
          this.pre1 = this.omtfeee;
          break;
        case 3:
          this.pre2 = this.allcordsprice;
          break;
        case 4:
          this.pre2 = this.slotfeee;
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.homePage {
  padding: 20px 0 80px;
  margin-top: 11vh;
  .banner {
    height: 620px;
    background: url("../assets/homebg.png") no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 15px;
    .box1 {
      width: 100px;
      height: 31px;
      background: rgba(255, 255, 255, 0.59);
      border-radius: 10px;
      font-size: 15px;
      font-family: PingFang SC;
      color: #000000;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      float: right;
    }
    .box2 {
      color: #000;
      padding: 15px 20px;
      box-sizing: border-box;
      text-align: left;
      overflow: hidden;
      .child1 {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #333333;
      }
      .child2 {
        margin-top: 15px;
        text-indent: 30px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #a3a3a3;
      }
      .child3 {
        text-indent: 30px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #a3a3a3;
      }
    }
  }
  .item {
    width: 100%;
    height: 237px;
    margin-top: 15px;
    background: url("../assets/home_item2.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 40px 20px;
    .box2 {
      color: #ffffff;
      .child1 {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: bold;
        text-align: right;
        margin-bottom: 10px;
      }
      .child2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .son1 {
          font-size: 9px;
          font-family: PingFang SC;
          font-weight: 400;
        }
        .son1:nth-child(2) {
          margin-left: 15px;
        }
      }
      .child3 {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        .son1 {
          width: 29px;
          height: 29px;
          margin-right: 15px;
        }
        .son2 {
          font-size: 24px;
          font-weight: bold;
          span {
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }
    .box3 {
      color: #ffffff;
      .child1 {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: bold;
        text-align: left;
        margin-bottom: 10px;
      }
      .child2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .son1 {
          font-size: 9px;
          font-family: PingFang SC;
          font-weight: 400;
        }
        :nth-child(2) {
          margin-left: 15px;
        }
      }
      .child3 {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        .son1 {
          width: 29px;
          height: 29px;
          margin-right: 15px;
        }
        .son2 {
          font-size: 24px;
          font-weight: bold;
          span {
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }
  }
  .item:nth-child(2) {
    background: url("../assets/home_item1.png") no-repeat;
    background-size: 100% 100%;
  }
}
</style>
